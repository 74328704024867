











import { Component, Vue } from 'nuxt-property-decorator'
import FedraLink from '@client/components/Helpers/FedraLink.vue'

@Component({
	name: 'MerchantRegistrationInvitationBanner',
	components: { FedraLink },
})
export default class MerchantRegistrationInvitationBanner extends Vue {

}

