import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'

@Module({
	name: 'Module/ProductFiltersModule',
	namespaced: true,
	stateFactory: true
})
export default class ProductFiltersModule extends VuexModule {

	isDisplayingFiltersSidebar = false

	get getIsDisplayingFiltersSidebar(): boolean {
		return this.isDisplayingFiltersSidebar
	}

	@VuexMutation
	toggleIsDisplayingFiltersSidebar(): void {
		this.isDisplayingFiltersSidebar = !this.isDisplayingFiltersSidebar
	}

	@VuexMutation
	setIsDisplayingFiltersSidebar(isDisplayingFiltersSidebar: boolean): void {
		this.isDisplayingFiltersSidebar = isDisplayingFiltersSidebar
	}

}
