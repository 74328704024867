import { BaseEntity } from '@client/store/Common/BaseEntity'
import { CategoryKP } from '@microservice/Category/API/Type/KeyProperties'
import { ProductFeatureKP } from '@microservice/Product/API/Type/KeyProperties'
import { ProductFeatureValue } from '@client/store/Domain/Feature/ProductFeatureValue'
import { ProductFeatureFilterDTO } from '@microservice/Product/API/DTO/ProductFeatureFilterDTO'

export default class ProductFeature extends BaseEntity {
	parentId: ProductFeatureKP
	name: string
	level: number
	isValid: boolean
	categoryIds: CategoryKP[]
	isDisplayedAsFilter: boolean
	values: ProductFeatureValue[]

	constructor(props: Partial<ProductFeature> | ProductFeatureFilterDTO) {
		super()
		Object.assign(this, props)
		if ('id' in props)
			this.parentId = props.id
		this.name = props.name
		this.values = Object.values(props.values).map((value)=> {
			return new ProductFeatureValue(value)
		})
	}

	get getValues(): ProductFeatureValue[] {
		return (this.values.length) ? this.values : []
	}

	setValues(values: ProductFeatureValue[] ): void {
		this.values = values
	}
}
