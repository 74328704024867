import { render, staticRenderFns } from "./ProductListingWithFilters.vue?vue&type=template&id=0b1db156&scoped=true&"
import script from "./ProductListingWithFilters.vue?vue&type=script&lang=ts&"
export * from "./ProductListingWithFilters.vue?vue&type=script&lang=ts&"
import style0 from "./ProductListingWithFilters.vue?vue&type=style&index=0&id=0b1db156&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b1db156",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchFilters: require('/home/node/client/components/SearchFilters.vue').default,ProductListing: require('/home/node/client/components/Product/ProductListing.vue').default})
