


































import { Component, getModule, Vue } from 'nuxt-property-decorator'
import FedraInstanceMetaModule from '@client/store/Module/FedraInstanceMetaModule'
import FedraLink from '@client/components/Helpers/FedraLink.vue'

@Component({
	name: 'AboutHome',
	components: { FedraLink }
})
export default class AboutHome extends Vue {

	fedraInstanceDataMD: FedraInstanceMetaModule

	get getFedraInstanceData(): any {
		return this.fedraInstanceDataMD.getActiveInstances
	}

	public getFlagImageSource(flagImageUrl: string): string {
		return flagImageUrl
	}

	created(): void {
		this.fedraInstanceDataMD = getModule(FedraInstanceMetaModule, this.$store)
	}

}

