






































import LazyHydrate from 'vue-lazy-hydration'
import { first, last, filter, times, constant } from 'lodash'
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faStarHalf } from '@fortawesome/free-solid-svg-icons/faStarHalf'

@Component({
	name: 'ProductReviewRating',
	components: { LazyHydrate }
})
export default class ProductReviewRating extends Vue {
	@Prop({ default: '' }) name: string
	@Prop({ default: false }) editingLocked: boolean
	@Prop({ default: false }) showReviewScoreText: boolean
	@Prop({ default: 16 }) size: number
	@Prop({ default: null, type: Number }) score: number
	@Prop({ default: 10 }) reviewCountMax: number
	@Prop({ default: 5 }) starCountMax: number

	isEditable: boolean = false
	newSelectionRatio: number = null
	selectedRatio: number = null

	public lockSelection(event: MouseEvent) {
		this.updateIsEditable(true)
		this.updateNewSelectionRatio(event)
		this.selectedRatio = this.newSelectionRatio
		this.updateIsEditable(false)
	}

	public reLockSelection(event: MouseEvent) {
		this.updateIsEditable(false)
		this.newSelectionRatio = this.selectedRatio
	}

	public unlockSelection(event: MouseEvent) {
		this.updateIsEditable(true)
	}

	public updateIsEditable(newState: boolean): void {
		if (!this.editingLocked) {
			this.isEditable = newState
		}
	}

	public updateNewSelectionRatio(event: MouseEvent) {
		if (!this.isEditable) {
			return
		}
		const target = this.$refs.ratingBoard as HTMLElement
		const leftBound = event.clientX - target.getBoundingClientRect().left
		const rightBound = target.getBoundingClientRect().right - target.getBoundingClientRect().left
		this.newSelectionRatio = leftBound / rightBound
	}

	get reviewCount(): number | null {
		if (null !== this.score && !isNaN(this.score as unknown as number)) {
			return Number(this.score)
		}

		return null
	}

	get liveReviewCountRatio(): number {
		let reviewCount = 0
		if (null !== this.newSelectionRatio) {
			reviewCount = this.newSelectionRatio * this.reviewCountMax
		}
		else if (null !== this.reviewCount) {
			reviewCount = this.reviewCount - 0.01
		}
		if (reviewCount > this.reviewCountMax)
			reviewCount = this.reviewCountMax
		if (reviewCount < 0)
			reviewCount = 0
		const liveReviewCountRatio = reviewCount / this.reviewCountMax
		return Number(liveReviewCountRatio.toFixed(1)) - 0.04
	}

	get liveReviewCount(): number {
		return Math.round(Number(this.liveReviewCountRatio.toFixed(2)) * this.reviewCountMax)
	}

	get reviewScoreText(): any {
		const breakpoints = <Array<{threshold: number, value: string}>> [
			{
				threshold: 0.2,
				value: 'Κακό'
			},
			{
				threshold: 0.3,
				value: 'Όχι και τόσο καλό'
			},
			{
				threshold: 0.5,
				value: 'Μέτριο'
			},
			{
				threshold: 0.6,
				value: 'Σχετικά καλό'
			},
			{
				threshold: 0.7,
				value: 'Καλό'
			},
			{
				threshold: 0.9,
				value: 'Πολύ Καλό'
			},
			{
				threshold: 1.0,
				value: 'Άριστο!'
			}
		]

		if (
			this.liveReviewCountRatio < 0.01
			|| (null === this.newSelectionRatio && (null === this.reviewCount || 0 === this.score))
		) {
			return ''
		}

		const matches = filter(
			breakpoints,
			(breakpoint) => breakpoint.threshold - 0.1 <= this.liveReviewCountRatio
		)

		if (undefined !== last(matches)) {
			return last(matches).value
		}

		return first(breakpoints).value
	}

	get foregroundStars(): IconDefinition[] {
		const reviewStarRatio = this.liveReviewCountRatio * this.starCountMax
		if (reviewStarRatio < 0.1) {
			return []
		}
		const stars: IconDefinition[] = times(Math.round(reviewStarRatio), constant(faStar))
		if ((reviewStarRatio % 1) < 0.5) {
			stars.push(faStarHalf)
		}
		return stars
	}

	get backgroundStars(): IconDefinition[] {
		return times(this.starCountMax, constant(faStar))
	}
}
