

















import LazyHydrate from 'vue-lazy-hydration'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { Component, Vue, getModule } from 'nuxt-property-decorator'
import PaginationModule from '@client/store/Module/PaginationModule'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import PaginationLink from '@client/store/Domain/Pagination/PaginationLink'
import QueryStringValueObject from '@client/store/Domain/Support/PathBuilder/QueryStringValueObject'

@Component({
	name: 'Pagination',
	components: { FedraLink, LazyHydrate }
})
export default class Pagination extends Vue {
	paginationMD: PaginationModule
	pathBuilderMD: PathBuilderModule

	get getPage(): number {
		return this.paginationMD.getPage
	}

	get getItemsPerPage(): number {
		return this.paginationMD.getItemsPerPage
	}

	get getTotalPages(): number {
		return this.paginationMD.getTotalPages
	}

	get getTotalPageItems(): number {
		return this.paginationMD.getTotalPageItems
	}

	get getPaginationLinks(): PaginationLink[] {
		return this.paginationMD.getPaginationLinks
	}

	getPaginationLinkStr(paginationLink: PaginationLink): string {
		const currentQueryString = new QueryStringValueObject(this.pathBuilderMD.path.querystring)

		return currentQueryString
			.mergeToQueryString(paginationLink.queryString)
			.path
	}

	created(): void {
		this.paginationMD = getModule(PaginationModule, this.$store)
		this.pathBuilderMD = getModule(PathBuilderModule, this.$store)
	}
}
