import RequestType from '@microservice/Common/Search/Type/RequestType'
import PaginationLink from '@client/store/Domain/Pagination/PaginationLink'
import { Module, VuexMutation, VuexModule, VuexAction } from 'nuxt-property-decorator'

export const defaultPageSize = 72

@Module({
	name: 'Module/PaginationModule',
	namespaced: true,
	stateFactory: true
})
export default class PaginationModule extends VuexModule {
	page = 1
	itemsPerPage = defaultPageSize
	totalPageItems = 0
	additionallyVisiblePaginationPageLinks = 4

	get getPage(): number {
		return Number(this.page)
	}

	get getItemsPerPage(): number {
		return this.itemsPerPage
	}

	get getTotalPageItems(): number {
		return this.totalPageItems
	}

	get getItemsOffset(): number {
		return (this.page - 1) * this.itemsPerPage
	}

	get getAdditionallyVisiblePaginationPageLinksPerSide(): number {
		return Math.round(this.additionallyVisiblePaginationPageLinks / 2)
	}

	get getTotalPages(): number {
		return Math.ceil(this.totalPageItems / this.itemsPerPage)
	}

	get getShouldShowSkipOneLeft(): boolean {
		return this.getPage > 1
	}

	get getShouldShowSkipAllLeft(): boolean {
		return this.getPage > 2
	}

	get getVisiblePaginationPagesLinksLeft(): number[] {
		let linkCount = this.getPage - this.getAdditionallyVisiblePaginationPageLinksPerSide
		if (linkCount <= 0)
			return []

		if (linkCount > this.getAdditionallyVisiblePaginationPageLinksPerSide)
			linkCount = this.getAdditionallyVisiblePaginationPageLinksPerSide

		return Array.from({length: linkCount}, (x, i) => i + (this.getPage - linkCount))
	}

	get getShouldShowSkipOneRight(): boolean {
		return this.getPage < this.getTotalPages
	}

	get getShouldShowSkipAllRight(): boolean {
		return this.getPage < this.getTotalPages - 1
	}

	get getVisiblePaginationPagesLinksRight(): number[] {
		let linkCount = this.getPage + this.getAdditionallyVisiblePaginationPageLinksPerSide
		if (linkCount >= this.getTotalPages)
			return []

		if (linkCount > this.getAdditionallyVisiblePaginationPageLinksPerSide)
			linkCount = this.getAdditionallyVisiblePaginationPageLinksPerSide

		return Array.from({length: linkCount}, (x, i) => i + (this.getPage + 1))
	}

	get getPaginationLinks(): PaginationLink[] {
		const finalPaginationLinksResult = []
		if (this.getShouldShowSkipAllLeft)
			finalPaginationLinksResult.push(new PaginationLink({
				name: '<<',
				page: 1,
				page_size: this.getItemsPerPage
			}))
		if (this.getShouldShowSkipOneLeft)
			finalPaginationLinksResult.push(new PaginationLink({
				name: '<',
				page: this.getPage - 1,
				page_size: this.getItemsPerPage
			}))
		for (const page of this.getVisiblePaginationPagesLinksLeft)
			finalPaginationLinksResult.push(new PaginationLink({
				name: page,
				page,
				page_size: this.getItemsPerPage
			}))
		finalPaginationLinksResult.push(new PaginationLink({
			name: this.getPage,
			selected: true,
			page: this.getPage,
			page_size: this.getItemsPerPage
		}))
		for (const page of this.getVisiblePaginationPagesLinksRight)
			finalPaginationLinksResult.push(new PaginationLink({
				name: page,
				page,
				page_size: this.getItemsPerPage
			}))
		if (this.getShouldShowSkipOneRight)
			finalPaginationLinksResult.push(new PaginationLink({
				name: '>',
				page: this.getPage + 1,
				page_size: this.getItemsPerPage
			}))
		if (this.getShouldShowSkipAllRight)
			finalPaginationLinksResult.push(new PaginationLink({
				name: '>>',
				page: this.getTotalPages,
				page_size: this.getItemsPerPage
			}))
		return finalPaginationLinksResult
	}

	@VuexAction
	async updateFromRequest(query: RequestType): Promise<void> {
		if ('page' in query)
			this.setPage(query.page)
		else
			this.setPage(1)

		if ('page_size' in query)
			this.setItemsPerPage(query.page_size)
	}

	@VuexMutation
	setPage(page: number): void {
		this.page = page
	}

	@VuexMutation
	setItemsPerPage(itemsPerPage: number): void {
		this.itemsPerPage = itemsPerPage
	}

	@VuexMutation
	setTotalPageItems(totalPageItems: number): void {
		this.totalPageItems = totalPageItems
	}
}
