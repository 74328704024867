import { map } from 'lodash'
import Product from '@client/store/Domain/Product/Product'
import { Read } from '@client/store/Common/DataMapperContract'
import FedraProductModel from '@microservice/Product/Model/FedraProductModel'

export class ProductDM implements Read {
	responseDataToEntity(data: Partial<Product>): Product {
		return new Product(data)
	}

	responseDataToCollection(data: Array<Partial<Product> | FedraProductModel>): Product[] {
		return map(data, (item) => new Product(item))
	}
}
