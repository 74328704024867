import Category from '@client/store/Domain/Category/Category'
import { Read } from '@client/store/Common/DataMapperContract'

export class CategoryDM implements Read {
	responseDataToEntity(data: Partial<Category>): Category {
		return new Category(data)
	}

	responseDataToCollection(data: Partial<Category>[]): Category[] {
		return data.map((item) => new Category(item))
	}
}
