var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cp-merchant_registration_contract-wrapper"},[_c('div',{staticClass:"cp-merchant_registration_contract"},[_c('div',{staticClass:"cp-merchant_registration_contract-header"}),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('div',{staticClass:"cp-merchant_registration_contract-body",domProps:{"innerHTML":_vm._s(_vm.$t(
				'merchant_register.merchant_contract',
				{
					person_name: _vm.personName,
					store_name: _vm.storeName,
					current_date: _vm.$d(new Date(), 'short')
				}
			))}})]),_vm._v(" "),_c('div',{staticClass:"cp-merchant_registration_contract-footer"},[_c('button',{staticClass:"cp-merchant_registration_contract-footer-action_btn decline",on:{"click":function($event){return _vm.$emit('declined')}}},[_vm._v(_vm._s(_vm.$t('merchant_register.decline')))]),_vm._v(" "),_c('button',{staticClass:"cp-merchant_registration_contract-footer-action_btn accept",on:{"click":function($event){return _vm.$emit('accepted')}}},[_vm._v(_vm._s(_vm.$t('merchant_register.accept')))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }