import { BaseEntity } from '@client/store/Common/BaseEntity'
import { ProductFeatureValueFilterDTO } from '@microservice/Product/API/DTO/ProductFeatureValueFilterDTO'

export class ProductFeatureValue extends BaseEntity {
	product_feature_value_id: number
	product_feature_id: number
	feature: string
	value: string
	order: number
	link: string
	totalEntries: number
	totalEntriesClean: number

	constructor(props: Partial<ProductFeatureValue> & ProductFeatureValueFilterDTO) {
		super()
		if (!props) return
		this.product_feature_value_id = props.id
		this.product_feature_id = props.productFeatureId
		this.feature = props.feature
		this.value = props.value
		this.order = props.order
		this.totalEntries = props.totalEntries
		this.totalEntriesClean = props.totalEntriesClean
	}

	setLink(link: string): void {
		this.link = link
	}
}

