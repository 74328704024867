















import { Component, Vue } from 'nuxt-property-decorator'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import MerchantFeedXMLSection from '@client/components/Merchant/MerchantFeedXMLSection.vue'

@Component({
	name: 'MerchantFeedXMLSectionFedraBot',
	components: { MerchantFeedXMLSection },
})
export default class MerchantFeedXMLSectionSupportedXMLSchemas extends Vue {

	codeIcon: IconDefinition = faCode

}

