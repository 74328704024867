import { Store } from 'vuex'
import { Context } from '@nuxt/types'
import { getModule } from 'vuex-module-decorators'
import BrandModule from '@client/store/Module/BrandModule'
import SocialModule from '@client/store/Module/SocialModule'
import SearchModule from '@client/store/Module/SearchModule'
import ProductModule from '@client/store/Module/ProductModule'
import CategoryModule from '@client/store/Module/CategoryModule'
import PageMetaModule from '@client/store/Module/PageMetaModule'
import TaxonomyModule from '@client/store/Module/TaxonomyModule'
import PaginationModule from '@client/store/Module/PaginationModule'
import PriceRangeModule from '@client/store/Module/PriceRangeModule'
import BreadcrumbModule from '@client/store/Module/BreadcrumbModule'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import ActiveFiltersModule from '@client/store/Module/ActiveFiltersModule'
import ProductFeatureModule from '@client/store/Module/ProductFeatureModule'
import FedraInstanceMetaModule from '@client/store/Module/FedraInstanceMetaModule'
import StaticPageRefreshModule from '@client/store/Module/StaticPageRefreshModule'
import SearchPageRefreshModule from '@client/store/Module/SearchPageRefreshModule'

const stateDirectoryInitializers = {
	'brandModule': ((store) => getModule(BrandModule, store)) as unknown as BrandModule,
	'socialModule': ((store) => getModule(SocialModule, store)) as unknown as SocialModule,
	'searchModule': ((store) => getModule(SearchModule, store)) as unknown as SearchModule,
	'productModule': ((store) => getModule(ProductModule, store)) as unknown as ProductModule,
	'categoryModule': ((store) => getModule(CategoryModule, store)) as unknown as CategoryModule,
	'pageMetaModule': ((store) => getModule(PageMetaModule, store)) as unknown as PageMetaModule,
	'taxonomyModule': ((store) => getModule(TaxonomyModule, store)) as unknown as TaxonomyModule,
	'paginationModule': ((store) => getModule(PaginationModule, store)) as unknown as PaginationModule,
	'priceRangeModule': ((store) => getModule(PriceRangeModule, store)) as unknown as PriceRangeModule,
	'breadcrumbModule': ((store) => getModule(BreadcrumbModule, store)) as unknown as BreadcrumbModule,
	'pathBuilderModule': ((store) => getModule(PathBuilderModule, store)) as unknown as PathBuilderModule,
	'activeFiltersModule': ((store) => getModule(ActiveFiltersModule, store)) as unknown as ActiveFiltersModule,
	'productFeatureModule': ((store) => getModule(ProductFeatureModule, store)) as unknown as ProductFeatureModule,
	'staticPageRefreshModule': ((store) => getModule(StaticPageRefreshModule, store)) as unknown as StaticPageRefreshModule,
	'searchPageRefreshModule': ((store) => getModule(SearchPageRefreshModule, store)) as unknown as SearchPageRefreshModule,
	'fedraInstanceMetaModule': ((store) => getModule(FedraInstanceMetaModule, store)) as unknown as FedraInstanceMetaModule,
}

// @ts-ignore
const stateDirectory: typeof stateDirectoryInitializers = {}

let context: Context

const initializeStores = (store: Store<any>): void => {
	Object.entries(stateDirectoryInitializers).forEach(([moduleKey, module]) => {
		stateDirectory[moduleKey] = (module as unknown as (store: Store<any>) => typeof module)(store)
	})
}

export const rehydrateContext = (newContext: Context): void => {
	context = newContext
}

export {
	initializeStores,
	stateDirectory,
	context
}
