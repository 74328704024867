import { capitalize } from 'lodash'
import { Vue } from 'nuxt-property-decorator'

export default (): void => {
	Vue.filter(
		'caseTransformer',
		function (value, instruction = null) {
			switch(instruction) {
			case 'capitalize':
				return capitalize(value)
			default:
				return value
			}
		}
	)
}
