import Brand from '@client/store/Domain/Brand/Brand'
import Collectable from '@client/store/Common/Collectable'
import { filter, first, isUndefined, sortBy, map } from 'lodash'
import Taggable from '@client/store/Domain/Support/Filter/Taggable'
import { BrandKP } from '@microservice/Brand/API/Type/KeyProperties'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import ActiveFiltersModule from '@client/store/Module/ActiveFiltersModule'
import TagCloudItem from '@client/store/Domain/Support/Filter/TagCloudItem'
import { BrandDM } from '@client/store/Domain/Brand/DataMapper/BrandDataMapper'
import SearchPageResults from '@microservice/Nuxtgen/API/DTO/SearchPageResults'
import { Module, VuexMutation, VuexModule, VuexAction } from 'nuxt-property-decorator'

@Module({
	name: 'Module/BrandModule',
	namespaced: true,
	stateFactory: true
})
export default class BrandModule extends VuexModule implements Taggable<BrandKP> {

	collection = new Collectable<Brand>()

	shouldBrandFiltersBeDisplayed = true

	get getShouldBrandFiltersBeDisplayed(): boolean {
		return this.shouldBrandFiltersBeDisplayed
	}

	get getCollection(): Collectable<Brand> {
		return this.collection
	}

	get getCollectionSorted(): Brand[] {
		return sortBy(this.collection.items, brand => brand.count).reverse()
	}

	get getSelectedTags(): (selectedIds: BrandKP[]) => TagCloudItem[] {
		return (selectedIds: BrandKP[]) => {
			return map(this.getCollectionChecked(selectedIds), (brand) => {
				return new TagCloudItem({
					name: brand.title,
					url: brand.link
				})
			})
		}
	}

	get getCollectionChecked(): (selectedIds: BrandKP[]) => Brand[] {
		return (selectedIds: BrandKP[]) => {
			return filter(this.getCollectionSorted, brand => selectedIds.includes(brand.brand_id))
		}
	}
	get getCollectionUnChecked(): (selectedIds: BrandKP[]) => Brand[] {
		return (selectedIds: BrandKP[]) => {
			return filter(this.getCollectionSorted, brand => !(selectedIds.includes(brand.brand_id)))
		}
	}

	@VuexAction({ rawError: true })
	async prepareUpdateFromResponse(
		{ response, activeFiltersMD, pathBuilderMD } : {
			response: SearchPageResults,
			activeFiltersMD: ActiveFiltersModule,
			pathBuilderMD: PathBuilderModule
		}
	): Promise<void> {
		let brandFilterDM = []
		try {
			brandFilterDM = new BrandDM().responseDataToCollection(response.filters.brands)
		} catch (e) {
			// response brands are empty or invalid
		} finally {
			activeFiltersMD.setBrands(brandFilterDM)
			pathBuilderMD.setFilters(activeFiltersMD.getFilters)
		}
	}

	@VuexAction({ rawError: true })
	async updateFromResponse(
		{ response, pathBuilderMD } : {
			response: SearchPageResults,
			pathBuilderMD: PathBuilderModule
		}
	): Promise<void> {
		const brandsDM = new BrandDM().responseDataToCollection(response.brands)
		const brandsWithLinks = brandsDM.map((brand) => {
			brand.setLink(pathBuilderMD.getBrandUrl(brand))
			return brand
		})
		this.setCollection(brandsWithLinks)
		const brandsLessThanThreshold = response.brands.length < 500
		const brandIsCanonicalOnRootCategory = !(
			1 === pathBuilderMD.getPath.brand.IDs.length
			&& isUndefined(first(pathBuilderMD.getPath.category.IDs))
		)
		this.setShouldBrandFiltersBeDisplayed(brandsLessThanThreshold || brandIsCanonicalOnRootCategory)
	}

	@VuexMutation
	setShouldBrandFiltersBeDisplayed(shouldBrandFiltersBeDisplayed: boolean): void {
		this.shouldBrandFiltersBeDisplayed = shouldBrandFiltersBeDisplayed
	}

	@VuexMutation
	setCollection(collection: Brand[]): void {
		this.collection = new Collectable<Brand>(collection)
	}
}
