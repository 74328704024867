
import { Component } from 'nuxt-property-decorator'
import StaticBasePage from '@client/pages/StaticBasePage.vue'

@Component({
	name: 'MerchantBasePage',
	extends: StaticBasePage
})
export default class MerchantBasePage extends StaticBasePage {

}

