






import { Component } from 'nuxt-property-decorator'
import MerchantBasePage from '@client/pages/Merchant/MerchantBasePage.vue'

@Component({
	name: 'MerchantForgotPasswordPage',
	watchQuery: [],
	extends: MerchantBasePage,
	components: {},
	layout: 'merchant'
})
export default class MerchantForgotPasswordPage extends MerchantBasePage {

}
