import { DirectiveOptions, VNode } from 'vue'

let registeredElements: {
	el: HTMLElement
	callback: () => Promise<void>
	vnode: VNode
}[] = []
let timedCallbackExecution: NodeJS.Timeout = null

function onViewportUpdate() {
	for (const element of registeredElements) {
		const elementBounds = element.el.getBoundingClientRect()
		const edgeTopCheck = (elementBounds.top + elementBounds.height) >= 0
		const edgeLeftCheck = elementBounds.left >= 0
		const edgeBottomCheck = elementBounds.bottom <= (window.innerHeight || document.documentElement.clientHeight)
		const edgeRightCheck = elementBounds.right <= (window.innerWidth || document.documentElement.clientWidth)
		const isInViewport = edgeTopCheck && edgeRightCheck && edgeBottomCheck && edgeLeftCheck
		if (isInViewport) {
			element.callback()
		}
	}
}

const InViewportDirective: DirectiveOptions = {
	bind(
		el,
		binding,
		vnode
	): void {
		if (registeredElements.length === 0) {
			window.addEventListener('resize', onViewportUpdate)
			window.addEventListener('scroll', onViewportUpdate)
		}
		registeredElements.push({
			el,
			callback: binding.value,
			vnode: vnode
		})
	},
	unbind(
		el
	): void {
		registeredElements = registeredElements.filter(element => element.el !== el)
		if (registeredElements.length === 0) {
			window.removeEventListener('resize', onViewportUpdate)
			window.removeEventListener('scroll', onViewportUpdate)
		}
	}
}
export default InViewportDirective
