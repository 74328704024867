























import SortingModule from '@client/store/Module/SortingModule'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { Component, Vue, getModule, Prop } from 'nuxt-property-decorator'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import QueryStringValueObject from '@client/store/Domain/Support/PathBuilder/QueryStringValueObject'

@Component({
	name: 'SortingOptions',
	components: { FedraLink }
})
export default class SortingOptions extends Vue {
	sortingMD: SortingModule
	pathBuilderMD: PathBuilderModule

	$refs!: {
		sortingCheckbox: HTMLInputElement
	}

	@Prop({ type: String, required: true })
	uniqueId!: string

	get getMyId(): string {
		return this.uniqueId
	}

	get canBeSorted(): boolean {
		return this.sortingMD.getCanBeSorted
	}

	get getSortingIcon(): faAngleDown {
		return faAngleDown
	}

	get getAvailableSortingOptions(): Record<string, string> {
		return this.sortingMD.getAvailableSortingMethods
	}

	get getSelectedSortingOption(): string {
		return this.sortingMD.getSelectedSortingOption
	}

	makeLink(sortingKey: string) {
		let currentQueryString = new QueryStringValueObject(this.pathBuilderMD.getPath.querystring)
		currentQueryString = currentQueryString.setQuery('sort', sortingKey)
		return currentQueryString.path
	}

	created () {
		this.sortingMD = getModule(SortingModule, this.$store)
		this.pathBuilderMD = getModule(PathBuilderModule, this.$store)
	}
}
