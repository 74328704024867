import * as Joi from 'joi'
import { BaseEntity } from '@client/store/Common/BaseEntity'
import ElasticBrandModel from '@microservice/Brand/Model/ElasticBrandModel'

export default class Brand extends BaseEntity {
	public readonly brand_id: number
	public slug: string
	public title: string
	public count: number
	public link: string

	constructor(props: Partial<Brand> | ElasticBrandModel) {
		super()
		this.validate(props)
		this.brand_id = props.brand_id
		this.slug = props.slug
		this.title = props.title
		this.count = props.count

		// Object.assign(this, props)
	}

	validate(props: Partial<Brand> | ElasticBrandModel): boolean {
		const { error } = Joi.object({
			brand_id: Joi.number().required(),
			title: Joi.string().required(),
			slug: Joi.string().required(),
			count: Joi.number().required()
		}).validate(props, { allowUnknown: true })

		if (error) console.warn('Brand Validation Error', error)

		return true
	}

	setLink(link: string): void {
		this.link = link
	}
}
