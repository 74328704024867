import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../../client/store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../../client/store/Common/BaseEntity.ts'), 'Common/BaseEntity.ts')
  resolveStoreModules(require('../../client/store/Common/Collectable.ts'), 'Common/Collectable.ts')
  resolveStoreModules(require('../../client/store/Common/DataMapperContract.ts'), 'Common/DataMapperContract.ts')
  resolveStoreModules(require('../../client/store/Common/HttpClient.ts'), 'Common/HttpClient.ts')
  resolveStoreModules(require('../../client/store/Common/Nestable.ts'), 'Common/Nestable.ts')
  resolveStoreModules(require('../../client/store/Model/ActiveFiltersModel.ts'), 'Model/ActiveFiltersModel.ts')
  resolveStoreModules(require('../../client/store/Model/BreadCrumbsModel.ts'), 'Model/BreadCrumbsModel.ts')
  resolveStoreModules(require('../../client/store/Module/ActiveFiltersModule.ts'), 'Module/ActiveFiltersModule.ts')
  resolveStoreModules(require('../../client/store/Module/BrandModule.ts'), 'Module/BrandModule.ts')
  resolveStoreModules(require('../../client/store/Module/BreadcrumbModule.ts'), 'Module/BreadcrumbModule.ts')
  resolveStoreModules(require('../../client/store/Module/CategoryModule.ts'), 'Module/CategoryModule.ts')
  resolveStoreModules(require('../../client/store/Module/ExternalSourcesModule.ts'), 'Module/ExternalSourcesModule.ts')
  resolveStoreModules(require('../../client/store/Module/FeatureValueModule.ts'), 'Module/FeatureValueModule.ts')
  resolveStoreModules(require('../../client/store/Module/FedraInstanceMetaModule.ts'), 'Module/FedraInstanceMetaModule.ts')
  resolveStoreModules(require('../../client/store/Module/PageMetaModule.ts'), 'Module/PageMetaModule.ts')
  resolveStoreModules(require('../../client/store/Module/PaginationModule.ts'), 'Module/PaginationModule.ts')
  resolveStoreModules(require('../../client/store/Module/PathBuilderModule.ts'), 'Module/PathBuilderModule.ts')
  resolveStoreModules(require('../../client/store/Module/PriceRangeModule.ts'), 'Module/PriceRangeModule.ts')
  resolveStoreModules(require('../../client/store/Module/ProductFeatureModule.ts'), 'Module/ProductFeatureModule.ts')
  resolveStoreModules(require('../../client/store/Module/ProductFiltersModule.ts'), 'Module/ProductFiltersModule.ts')
  resolveStoreModules(require('../../client/store/Module/ProductModule.ts'), 'Module/ProductModule.ts')
  resolveStoreModules(require('../../client/store/Module/SearchModule.ts'), 'Module/SearchModule.ts')
  resolveStoreModules(require('../../client/store/Module/SearchPageRefreshModule.ts'), 'Module/SearchPageRefreshModule.ts')
  resolveStoreModules(require('../../client/store/Module/SocialModule.ts'), 'Module/SocialModule.ts')
  resolveStoreModules(require('../../client/store/Module/SortingModule.ts'), 'Module/SortingModule.ts')
  resolveStoreModules(require('../../client/store/Module/StaticPageRefreshModule.ts'), 'Module/StaticPageRefreshModule.ts')
  resolveStoreModules(require('../../client/store/Module/TaxonomyModule.ts'), 'Module/TaxonomyModule.ts')
  resolveStoreModules(require('../../client/store/Utility/StoreAccessor.ts'), 'Utility/StoreAccessor.ts')
  resolveStoreModules(require('../../client/store/Domain/Brand/Brand.ts'), 'Domain/Brand/Brand.ts')
  resolveStoreModules(require('../../client/store/Domain/Category/Category.ts'), 'Domain/Category/Category.ts')
  resolveStoreModules(require('../../client/store/Domain/Feature/ProductFeature.ts'), 'Domain/Feature/ProductFeature.ts')
  resolveStoreModules(require('../../client/store/Domain/Feature/ProductFeatureValue.ts'), 'Domain/Feature/ProductFeatureValue.ts')
  resolveStoreModules(require('../../client/store/Domain/Pagination/Pagination.ts'), 'Domain/Pagination/Pagination.ts')
  resolveStoreModules(require('../../client/store/Domain/Pagination/PaginationLink.ts'), 'Domain/Pagination/PaginationLink.ts')
  resolveStoreModules(require('../../client/store/Domain/Product/Product.ts'), 'Domain/Product/Product.ts')
  resolveStoreModules(require('../../client/store/Domain/Product/ProductDataMapper.ts'), 'Domain/Product/ProductDataMapper.ts')
  resolveStoreModules(require('../../client/store/Domain/Brand/DataMapper/BrandDataMapper.ts'), 'Domain/Brand/DataMapper/BrandDataMapper.ts')
  resolveStoreModules(require('../../client/store/Domain/Category/DataMapper/CategoryDataMapper.ts'), 'Domain/Category/DataMapper/CategoryDataMapper.ts')
  resolveStoreModules(require('../../client/store/Domain/Feature/DataMapper/ProductFeatureDM.ts'), 'Domain/Feature/DataMapper/ProductFeatureDM.ts')
  resolveStoreModules(require('../../client/store/Domain/Feature/DataMapper/ProductFeatureValueDM.ts'), 'Domain/Feature/DataMapper/ProductFeatureValueDM.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/FedraInstance/FedraInstanceInfo.ts'), 'Domain/Support/FedraInstance/FedraInstanceInfo.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/FedraInstance/RegionMetaInfo.ts'), 'Domain/Support/FedraInstance/RegionMetaInfo.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/Filter/TagCloudItem.ts'), 'Domain/Support/Filter/TagCloudItem.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/Filter/Taggable.ts'), 'Domain/Support/Filter/Taggable.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/Filter/ToggleButtonDisplayCSSStateEnum.ts'), 'Domain/Support/Filter/ToggleButtonDisplayCSSStateEnum.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/PageRefresh/FedraNuxtAppContext.ts'), 'Domain/Support/PageRefresh/FedraNuxtAppContext.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/PathBuilder/AvailablePageParams.ts'), 'Domain/Support/PathBuilder/AvailablePageParams.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/PathBuilder/GenericPageParams.ts'), 'Domain/Support/PathBuilder/GenericPageParams.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/PathBuilder/PathSegmentValueObject.ts'), 'Domain/Support/PathBuilder/PathSegmentValueObject.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/PathBuilder/PathValueObject.ts'), 'Domain/Support/PathBuilder/PathValueObject.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/PathBuilder/QueryStringValueObject.ts'), 'Domain/Support/PathBuilder/QueryStringValueObject.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/PathBuilder/SearchPageParams.ts'), 'Domain/Support/PathBuilder/SearchPageParams.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/Social/SocialMeta.ts'), 'Domain/Support/Social/SocialMeta.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/Social/SocialMetaUsageLocation.ts'), 'Domain/Support/Social/SocialMetaUsageLocation.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/RichResults/org.schema/BreadcrumbList.ts'), 'Domain/Support/RichResults/org.schema/BreadcrumbList.ts')
  resolveStoreModules(require('../../client/store/Domain/Support/RichResults/org.schema/ListItem.ts'), 'Domain/Support/RichResults/org.schema/ListItem.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
