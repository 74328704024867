




































































import { getModule } from 'vuex-module-decorators'
import { Component, Vue } from 'nuxt-property-decorator'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import SearchBar from '@client/components/SearchBar/SearchBar.vue'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import GenericModal from '@client/components/Utilities/GenericModal.vue'
import AboutBurgerButton from '@client/components/About/AboutBurgerButton.vue'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'
import SearchPageHeaderNavigation from '@client/components/PageHeader/SearchPageHeaderNavigation.vue'

@Component({
	name: 'PageHeader',
	components: { FedraLink, SearchBar, AboutBurgerButton, GenericModal, SearchPageHeaderNavigation }
})
export default class PageHeader extends Vue {

	navigationSidebarHidden: boolean = true
	userIcon : IconDefinition = faUser
	globalIcon: IconDefinition = faGlobe
	navigationOptionsTogglePointerEvents: string = 'none'
	externalSourcesMD: ExternalSourcesModule

	$refs!: {
		navigationOptions: HTMLInputElement
		pageHeaderNavigationModal: GenericModal
		pageHeaderBurgerButton: AboutBurgerButton
	}

	public openBurger(): void {
		this.$refs.navigationOptions.checked = true
		this.$refs.pageHeaderNavigationModal.openModal()
	}

	public closeBurger(): void {
		this.$refs.navigationOptions.checked = false
	}

	get getIsDisplayingNavigationSidebar(): boolean {
		return !this.navigationSidebarHidden
	}

	get getUserLoginUrl(): string {
		return this.externalSourcesMD.getMemberWelcomeUrl
	}

	get getSelectCountryUrl(): string {
		return this.externalSourcesMD.getGlobalRoot
	}

	public updateIsDisplayingNavigationSidebar(navigationSidebarHidden: boolean): void {
		this.navigationSidebarHidden = navigationSidebarHidden
	}

	created() {
		this.externalSourcesMD = getModule(ExternalSourcesModule, this.$store)
	}

	mounted() {
		if (process.client)
			this.navigationOptionsTogglePointerEvents = 'auto'
	}

}
