




import * as Lottie from 'lottie-web'
import { Component, Prop, Model, Vue } from 'nuxt-property-decorator'
import { AnimationConfigWithData, AnimationConfigWithPath, AnimationItem, LottiePlayer } from 'lottie-web'

@Component({
	name: 'FedraLottiePlayer'
})
export default class FedraLottiePlayer extends Vue {

	anim: AnimationItem

	@Prop({ type: [Object, String], required: true })
	animationData: AnimationItem|string

	@Prop({ type: [Boolean, Number], default: false })
	loop: boolean|number

	@Prop({ type: Boolean, default: true })
	autoPlay: boolean

	@Prop({ type: String, default: 'svg' })
	renderer: string

	@Prop({ type: Number, default: 1 })
	speed: number

	get getLottieInstance(): LottiePlayer {
		return Lottie as LottiePlayer
	}

	created() {

	}

	mounted() {
		this.init()
		this.$on('lottie-replay', () => this.replay())
		this.$on('lottie-play', () => this.play())
		this.$on('lottie-stop', () => this.stop())
		this.$on('lottie-pause', () => this.pause())
	}

	beforeDestroy() {
		if (this.anim)
			this.anim.destroy()
	}

	init() {
		const settings = <AnimationConfigWithPath | AnimationConfigWithData> {
			container: this.$refs.animation,
			renderer: this.renderer,
			loop: this.loop,
			autoplay: this.autoPlay,
			animationData: this.animationData
		}
		this.anim = this.getLottieInstance.loadAnimation(settings)
		this.anim.addEventListener('loopComplete', () => {
			this.$emit('loopComplete', this.anim)
		})
		this.anim.addEventListener('complete', () => {
			this.$emit('complete', this.anim)
		})
		this.anim.addEventListener('enterFrame', () => {
			this.$emit('enterFrame', this.anim)
		})
	}

	replay() {
		this.anim.stop()
		this.anim.play()
	}

	play() {
		if (this.anim)
			this.anim.play()
	}

	stop() {
		if (this.anim)
			this.anim.stop()
	}

	pause() {
		if (this.anim)
			this.anim.pause()
	}
}
