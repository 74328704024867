

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"dateTimeFormats":{"de":{"short":{"year":"numeric","month":"short","day":"numeric"}},"el":{"short":{"year":"numeric","month":"short","day":"numeric"}},"es":{"short":{"year":"numeric","month":"short","day":"numeric"}},"fr":{"short":{"year":"numeric","month":"short","day":"numeric"}},"it":{"short":{"year":"numeric","month":"short","day":"numeric"}},"mx":{"short":{"year":"numeric","month":"short","day":"numeric"}},"se":{"short":{"year":"numeric","month":"short","day":"numeric"}},"us":{"short":{"year":"numeric","month":"short","day":"numeric"}},"en":{"short":{"year":"numeric","month":"short","day":"numeric"}}}},
  vueI18nLoader: false,
  locales: [{"code":"de","name":"Deutsch","iso":"de-DE","file":"de.ts","domain":"de.fedra.com","icon":""},{"code":"el","name":"Ελληνικά","iso":"el-GR","file":"el.ts","domain":"el.fedra.com","icon":""},{"code":"es","name":"Español","iso":"es-ES","file":"es.ts","domain":"es.fedra.com","icon":""},{"code":"fr","name":"Français","iso":"fr-FR","file":"fr.ts","domain":"fr.fedra.com","icon":""},{"code":"it","name":"Italiano","iso":"it-IT","file":"it.ts","domain":"it.fedra.com","icon":""},{"code":"mx","name":"Español","iso":"es-MX","file":"mx.ts","domain":"mx.fedra.com","icon":""},{"code":"se","name":"Svenska","iso":"se-SE","file":"se.ts","domain":"se.fedra.com","icon":""},{"code":"us","name":"English","iso":"en-US","file":"us.ts","domain":"us.fedra.com","icon":""},{"code":"uk","name":"English","iso":"en-US","file":"uk.ts","domain":"uk.fedra.com","icon":""}],
  defaultLocale: "us",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: {"skipNuxtState":true},
  langDir: "/home/node/client/locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: true,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"de","name":"Deutsch","iso":"de-DE","file":"de.ts","domain":"de.fedra.com","icon":""},{"code":"el","name":"Ελληνικά","iso":"el-GR","file":"el.ts","domain":"el.fedra.com","icon":""},{"code":"es","name":"Español","iso":"es-ES","file":"es.ts","domain":"es.fedra.com","icon":""},{"code":"fr","name":"Français","iso":"fr-FR","file":"fr.ts","domain":"fr.fedra.com","icon":""},{"code":"it","name":"Italiano","iso":"it-IT","file":"it.ts","domain":"it.fedra.com","icon":""},{"code":"mx","name":"Español","iso":"es-MX","file":"mx.ts","domain":"mx.fedra.com","icon":""},{"code":"se","name":"Svenska","iso":"se-SE","file":"se.ts","domain":"se.fedra.com","icon":""},{"code":"us","name":"English","iso":"en-US","file":"us.ts","domain":"us.fedra.com","icon":""},{"code":"uk","name":"English","iso":"en-US","file":"uk.ts","domain":"uk.fedra.com","icon":""}],
  localeCodes: ["de","el","es","fr","it","mx","se","us","uk"],
  additionalMessages: [],
}

export const localeMessages = {
  'de.ts': () => import('../../../client/locales/de.ts' /* webpackChunkName: "lang-de.ts" */),
  'el.ts': () => import('../../../client/locales/el.ts' /* webpackChunkName: "lang-el.ts" */),
  'es.ts': () => import('../../../client/locales/es.ts' /* webpackChunkName: "lang-es.ts" */),
  'fr.ts': () => import('../../../client/locales/fr.ts' /* webpackChunkName: "lang-fr.ts" */),
  'it.ts': () => import('../../../client/locales/it.ts' /* webpackChunkName: "lang-it.ts" */),
  'mx.ts': () => import('../../../client/locales/mx.ts' /* webpackChunkName: "lang-mx.ts" */),
  'se.ts': () => import('../../../client/locales/se.ts' /* webpackChunkName: "lang-se.ts" */),
  'us.ts': () => import('../../../client/locales/us.ts' /* webpackChunkName: "lang-us.ts" */),
  'uk.ts': () => import('../../../client/locales/uk.ts' /* webpackChunkName: "lang-uk.ts" */),
}
