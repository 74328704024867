import { NuxtI18nInstance } from '@nuxtjs/i18n'
import PageMeta from '@microservice/Nuxtgen/API/DTO/PageMeta'
import CategoryModule from '@client/store/Module/CategoryModule'
import BreadcrumbModule from '@client/store/Module/BreadcrumbModule'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'
import { Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator'
import PathValueObject from '@client/store/Domain/Support/PathBuilder/PathValueObject'

@Module({
	name: 'Module/PageMetaModule',
	namespaced: true,
	stateFactory: true
})
export default class PageMetaModule extends VuexModule {

	pageMeta: PageMeta

	cookieFirstKeys = {
		de: '50f57d23-cd97-4a21-9d09-05057a9c5c6a',
		el: '3a1b2703-e39e-4396-9245-b808ab4b0eb0',
		es: '21ffe47b-0feb-4111-9d92-600721681f7e',
		fr: 'e2c7c7a4-b634-48c1-b847-ab80aac393e3',
		it: 'eea0b25f-3b6d-4b62-8a9b-74f6f3ce4e66'
	}

	get getPageMeta(): PageMeta {
		return this.pageMeta
	}

	@VuexMutation
	setPageMeta(pageMeta: PageMeta): void {
		this.pageMeta = pageMeta
	}

	@VuexAction
	async updateFromResponse(
		{ i18n, pageMeta, pathBuilderMD, externalSourcesMD, breadcrumbMD, categoryMD } : {
			i18n: NuxtI18nInstance
			pageMeta: PageMeta,
			categoryMD: CategoryModule,
			breadcrumbMD: BreadcrumbModule,
			pathBuilderMD: PathBuilderModule,
			externalSourcesMD: ExternalSourcesModule
		}
	): Promise<void> {
		let meta = new PageMeta(pageMeta)
		const canonicalUrl = new PathValueObject()
		canonicalUrl.category = pathBuilderMD.path.category
		if (meta.isBrandCanonical)
			canonicalUrl.brand = pathBuilderMD.path.brand
		if (meta.isProductFeatureValueCanonical)
			canonicalUrl.feature = pathBuilderMD.path.feature

		meta = meta
			.setUrlContent(`${externalSourcesMD.getInstanceRoot}${pathBuilderMD.path.build}`)
			.setCanonicalUrl(`${externalSourcesMD.getInstanceRoot}${canonicalUrl.build}`)
			.appendRichResult(await breadcrumbMD.getStructuredData(externalSourcesMD))
			.appendRichResult(await categoryMD.getStructuredData(externalSourcesMD))
			.appendRichResult({
				'@context': 'https://schema.org',
				'@type': 'WebSite',
				'url': externalSourcesMD.getInstanceRoot,
				'potentialAction': {
					'@type': 'SearchAction',
					'target': `${externalSourcesMD.getInstanceRoot}/search/?keyphrase={search_term_string}`,
					'query-input': 'required name=search_term_string'
				}
			})

		if ('true' === process.env.IS_COOKIEFIRST_ENABLED && i18n.locale in this.cookieFirstKeys) {
			meta = meta.appendCustomScript({
				src: 'https://consent.cookiefirst.com/banner.js',
				'data-cookiefirst-key': this.cookieFirstKeys[i18n.locale],
				'async': true,
				'defer': true
			})
		}

		this.setPageMeta(meta)
	}

}
