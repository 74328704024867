
































































import { isEmpty } from 'lodash'
import { Component, Vue } from 'nuxt-property-decorator'
import FedraLink from '@client/components/Helpers/FedraLink.vue'

@Component({
	name: 'PageFooter',
	components: { FedraLink }
})
export default class PageFooter extends Vue {
  $i18n: any

	isSocialAvailable(social: string): boolean {
		if (!this.$i18n.te(this.getSocialLink(social)))
			return false
		const socialLink = this.$i18n.t(this.getSocialLink(social))
		return !(isEmpty(socialLink) || socialLink === '-' || isEmpty(this.getSocialIcon(social).trim()))
	}

	getSocialLink(social: string): string {
		return `social.${social}_link`
	}

	getSocialText(social: string): string {
		return `social.${social}_text`
	}

	getSocialIcon(social: string): string {
		try {
			return require(`~/assets/images/social_${social}_icon.png`) || ''
		} catch (e) {
			return ''
		}
	}

}
