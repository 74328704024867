import { mapKeys, snakeCase } from 'lodash'
import Sorting from '@microservice/Nuxtgen/API/Enum/Sorting'
import Taxonomy from '@microservice/Nuxtgen/API/Enum/Taxonomy'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import RequestType from '@microservice/Common/Search/Type/RequestType'
import { Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator'

@Module({
	name: 'Module/SortingModule',
	namespaced: true,
	stateFactory: true
})
export default class SortingModule extends VuexModule {

	selectedSortingOption: Sorting

	currentTaxonomy: Taxonomy

	get getCanBeSorted(): boolean {
		return Taxonomy.SEARCH !== this.currentTaxonomy
	}

	get getSelectedSortingOption(): Sorting {
		return this.selectedSortingOption
	}

	get getAvailableSortingMethods(): Record<string, string> {
		return mapKeys(Sorting, (sortingValue, sortingKey) => snakeCase(sortingKey))
	}

	@VuexAction
	async updateFromRequest(
		{ pathBuilderMD, query } : {
			pathBuilderMD: PathBuilderModule,
			query: RequestType
		}
	): Promise<string> {
		let sort = '-store_count'
		this.setSelectedSorting(Sorting.STORE_COUNT)
		if ('sort' in query) {
			if (snakeCase('ALPHABETICALLY') === query.sort) {
				this.setSelectedSorting(Sorting.ALPHABETICALLY)
				sort = 'title.keyword'
			}
			if (snakeCase('BEST_RATING') === query.sort) {
				this.setSelectedSorting(Sorting.BEST_RATING)
				sort = '-review_score'
			}
			if (snakeCase('PRICE_ASC') === query.sort) {
				this.setSelectedSorting(Sorting.PRICE_ASC)
				sort = 'min_price'
			}
			if (snakeCase('PRICE_DESC') === query.sort) {
				this.setSelectedSorting(Sorting.PRICE_DESC)
				sort = '-min_price'
			}
			if (snakeCase('STORE_COUNT') === query.sort) {
				sort = '-store_count'
			}
		}

		if (query.sort)
			pathBuilderMD.setQueryStringByKey({ key: 'sort', value: query.sort })

		return sort
	}

	@VuexAction
	async updateFromResponse(taxonomy: Taxonomy): Promise<void> {
		this.setCurrentTaxonomy(taxonomy)
	}

	@VuexMutation
	setSelectedSorting(sorting: Sorting): void {
		this.selectedSortingOption = sorting
	}

	@VuexMutation
	setCurrentTaxonomy(taxonomy: Taxonomy): void {
		this.currentTaxonomy = taxonomy
	}

}
