export const LoginOrRegisterButton = () => import('../../../client/components/LoginOrRegisterButton.vue' /* webpackChunkName: "components/login-or-register-button" */).then(c => wrapFunctional(c.default || c))
export const SearchFilters = () => import('../../../client/components/SearchFilters.vue' /* webpackChunkName: "components/search-filters" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../../client/components/Breadcrumbs/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const AboutBurgerButton = () => import('../../../client/components/About/AboutBurgerButton.vue' /* webpackChunkName: "components/about-burger-button" */).then(c => wrapFunctional(c.default || c))
export const AboutFedraWorld = () => import('../../../client/components/About/AboutFedraWorld.vue' /* webpackChunkName: "components/about-fedra-world" */).then(c => wrapFunctional(c.default || c))
export const AboutHeader = () => import('../../../client/components/About/AboutHeader.vue' /* webpackChunkName: "components/about-header" */).then(c => wrapFunctional(c.default || c))
export const AboutHome = () => import('../../../client/components/About/AboutHome.vue' /* webpackChunkName: "components/about-home" */).then(c => wrapFunctional(c.default || c))
export const AboutJoinUs = () => import('../../../client/components/About/AboutJoinUs.vue' /* webpackChunkName: "components/about-join-us" */).then(c => wrapFunctional(c.default || c))
export const AboutWeAreFedra = () => import('../../../client/components/About/AboutWeAreFedra.vue' /* webpackChunkName: "components/about-we-are-fedra" */).then(c => wrapFunctional(c.default || c))
export const CategoryBanner = () => import('../../../client/components/Category/CategoryBanner.vue' /* webpackChunkName: "components/category-banner" */).then(c => wrapFunctional(c.default || c))
export const CategoryHeader = () => import('../../../client/components/Category/CategoryHeader.vue' /* webpackChunkName: "components/category-header" */).then(c => wrapFunctional(c.default || c))
export const CategoryListing = () => import('../../../client/components/Category/CategoryListing.vue' /* webpackChunkName: "components/category-listing" */).then(c => wrapFunctional(c.default || c))
export const CategoryListingItem = () => import('../../../client/components/Category/CategoryListingItem.vue' /* webpackChunkName: "components/category-listing-item" */).then(c => wrapFunctional(c.default || c))
export const CategoryListingItemSmall = () => import('../../../client/components/Category/CategoryListingItemSmall.vue' /* webpackChunkName: "components/category-listing-item-small" */).then(c => wrapFunctional(c.default || c))
export const CategoryListingSmall = () => import('../../../client/components/Category/CategoryListingSmall.vue' /* webpackChunkName: "components/category-listing-small" */).then(c => wrapFunctional(c.default || c))
export const FiltersBrandsFilter = () => import('../../../client/components/Filters/BrandsFilter.vue' /* webpackChunkName: "components/filters-brands-filter" */).then(c => wrapFunctional(c.default || c))
export const FiltersCategoriesFilter = () => import('../../../client/components/Filters/CategoriesFilter.vue' /* webpackChunkName: "components/filters-categories-filter" */).then(c => wrapFunctional(c.default || c))
export const FiltersCheckedFilters = () => import('../../../client/components/Filters/CheckedFilters.vue' /* webpackChunkName: "components/filters-checked-filters" */).then(c => wrapFunctional(c.default || c))
export const FiltersFeaturesFilter = () => import('../../../client/components/Filters/FeaturesFilter.vue' /* webpackChunkName: "components/filters-features-filter" */).then(c => wrapFunctional(c.default || c))
export const FiltersPriceRangeFilter = () => import('../../../client/components/Filters/PriceRangeFilter.vue' /* webpackChunkName: "components/filters-price-range-filter" */).then(c => wrapFunctional(c.default || c))
export const HelpersFedraLink = () => import('../../../client/components/Helpers/FedraLink.vue' /* webpackChunkName: "components/helpers-fedra-link" */).then(c => wrapFunctional(c.default || c))
export const ChartsMinPriceHistogram = () => import('../../../client/components/Charts/MinPriceHistogram.vue' /* webpackChunkName: "components/charts-min-price-histogram" */).then(c => wrapFunctional(c.default || c))
export const LegacyLanguageSelector = () => import('../../../client/components/LegacyLanguageSelector/LegacyLanguageSelector.vue' /* webpackChunkName: "components/legacy-language-selector" */).then(c => wrapFunctional(c.default || c))
export const ListingPagination = () => import('../../../client/components/Listing/Pagination.vue' /* webpackChunkName: "components/listing-pagination" */).then(c => wrapFunctional(c.default || c))
export const ListingSortingOptions = () => import('../../../client/components/Listing/SortingOptions.vue' /* webpackChunkName: "components/listing-sorting-options" */).then(c => wrapFunctional(c.default || c))
export const MerchantBackToHomeButton = () => import('../../../client/components/Merchant/MerchantBackToHomeButton.vue' /* webpackChunkName: "components/merchant-back-to-home-button" */).then(c => wrapFunctional(c.default || c))
export const MerchantFeedXMLHeaderBurgerNavigation = () => import('../../../client/components/Merchant/MerchantFeedXMLHeaderBurgerNavigation.vue' /* webpackChunkName: "components/merchant-feed-x-m-l-header-burger-navigation" */).then(c => wrapFunctional(c.default || c))
export const MerchantFeedXMLSection = () => import('../../../client/components/Merchant/MerchantFeedXMLSection.vue' /* webpackChunkName: "components/merchant-feed-x-m-l-section" */).then(c => wrapFunctional(c.default || c))
export const MerchantFeedXMLSectionFedraBot = () => import('../../../client/components/Merchant/MerchantFeedXMLSectionFedraBot.vue' /* webpackChunkName: "components/merchant-feed-x-m-l-section-fedra-bot" */).then(c => wrapFunctional(c.default || c))
export const MerchantFeedXMLSectionSchemaFields = () => import('../../../client/components/Merchant/MerchantFeedXMLSectionSchemaFields.vue' /* webpackChunkName: "components/merchant-feed-x-m-l-section-schema-fields" */).then(c => wrapFunctional(c.default || c))
export const MerchantFeedXMLSectionSupportedXMLSchemas = () => import('../../../client/components/Merchant/MerchantFeedXMLSectionSupportedXMLSchemas.vue' /* webpackChunkName: "components/merchant-feed-x-m-l-section-supported-x-m-l-schemas" */).then(c => wrapFunctional(c.default || c))
export const MerchantHeader = () => import('../../../client/components/Merchant/MerchantHeader.vue' /* webpackChunkName: "components/merchant-header" */).then(c => wrapFunctional(c.default || c))
export const MerchantHeaderNavigation = () => import('../../../client/components/Merchant/MerchantHeaderNavigation.vue' /* webpackChunkName: "components/merchant-header-navigation" */).then(c => wrapFunctional(c.default || c))
export const MerchantLoginOrRegisterButton = () => import('../../../client/components/Merchant/MerchantLoginOrRegisterButton.vue' /* webpackChunkName: "components/merchant-login-or-register-button" */).then(c => wrapFunctional(c.default || c))
export const MerchantRegisterForm = () => import('../../../client/components/Merchant/MerchantRegisterForm.vue' /* webpackChunkName: "components/merchant-register-form" */).then(c => wrapFunctional(c.default || c))
export const MerchantRegisterUSPs = () => import('../../../client/components/Merchant/MerchantRegisterUSPs.vue' /* webpackChunkName: "components/merchant-register-u-s-ps" */).then(c => wrapFunctional(c.default || c))
export const MerchantRegistrationContract = () => import('../../../client/components/Merchant/MerchantRegistrationContract.vue' /* webpackChunkName: "components/merchant-registration-contract" */).then(c => wrapFunctional(c.default || c))
export const MerchantRegistrationInvitationBanner = () => import('../../../client/components/Merchant/MerchantRegistrationInvitationBanner.vue' /* webpackChunkName: "components/merchant-registration-invitation-banner" */).then(c => wrapFunctional(c.default || c))
export const MetaBox = () => import('../../../client/components/MetaBox/MetaBox.vue' /* webpackChunkName: "components/meta-box" */).then(c => wrapFunctional(c.default || c))
export const PageFooter = () => import('../../../client/components/PageFooter/PageFooter.vue' /* webpackChunkName: "components/page-footer" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../../client/components/PageHeader/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageHeaderSearchPageHeaderNavigation = () => import('../../../client/components/PageHeader/SearchPageHeaderNavigation.vue' /* webpackChunkName: "components/page-header-search-page-header-navigation" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../../client/components/Product/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductListing = () => import('../../../client/components/Product/ProductListing.vue' /* webpackChunkName: "components/product-listing" */).then(c => wrapFunctional(c.default || c))
export const ProductListingWithFilters = () => import('../../../client/components/Product/ProductListingWithFilters.vue' /* webpackChunkName: "components/product-listing-with-filters" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewRating = () => import('../../../client/components/Product/ProductReviewRating.vue' /* webpackChunkName: "components/product-review-rating" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../../client/components/SearchBar/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const UtilitiesFedraLottiePlayer = () => import('../../../client/components/Utilities/FedraLottiePlayer.vue' /* webpackChunkName: "components/utilities-fedra-lottie-player" */).then(c => wrapFunctional(c.default || c))
export const UtilitiesGenericModal = () => import('../../../client/components/Utilities/GenericModal.vue' /* webpackChunkName: "components/utilities-generic-modal" */).then(c => wrapFunctional(c.default || c))
export const UtilitiesNoScript = () => import('../../../client/components/Utilities/NoScript.vue' /* webpackChunkName: "components/utilities-no-script" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
