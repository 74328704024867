import { BaseEntity } from '@client/store/Common/BaseEntity'
import FedraProductModel from '@microservice/Product/Model/FedraProductModel'

export default class Product extends BaseEntity {

	constructor(props?: FedraProductModel | Partial<Product>) {
		super()
		Object.assign(this, props)
	}
}
