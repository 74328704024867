import { map } from 'lodash'
import ListItem from '@client/store/Domain/Support/RichResults/org.schema/ListItem'

export default class BreadcrumbList {
	'@context': 'https://schema.org'
	'@type': 'BreadcrumbList'
	itemListElement: ListItem[]

	constructor(data?: Partial<BreadcrumbList>) {
		Object.assign(this, data)
		this.itemListElement = map(this.itemListElement, item => {
			return new ListItem({
				'@type': 'ListItem',
				...item
			})
		})
	}
}
