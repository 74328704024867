


























































import VueHorizontal from 'vue-horizontal'
import Category from '@client/store/Domain/Category/Category'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { Component, getModule, Prop, Vue } from 'nuxt-property-decorator'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons/faChevronCircleLeft'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight'

@Component({
	name: 'CategoryListingItem',
	components: { FedraLink, VueHorizontal }
})
export default class CategoryListingItem extends Vue {

	@Prop({ type: Object, required: true })
	category!: Category

	@Prop({ type: String, required: true })
	categoryName!: string

	@Prop({ type: String, required: true })
	categoryThumbnailImage!: string

	hasPrev: boolean = false
	hasNext: boolean = true

	leftArrowIcon: IconDefinition = faChevronCircleLeft
	rightArrowIcon: IconDefinition = faChevronCircleRight

	$refs!: {
		horizontal: VueHorizontal,
	}

	prev() {
		this.$refs.horizontal.prev()
	}
	next() {
		this.$refs.horizontal.next()
	}

	onScroll({hasPrev, hasNext}) {
		this.hasPrev = hasPrev
		this.hasNext = hasNext
	}

}
