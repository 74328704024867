
































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import LazyHydrate from 'vue-lazy-hydration'

@Component({
	name: 'MerchantRegistrationContract',
	components: { LazyHydrate }
})
export default class MerchantRegistrationContract extends Vue {
	@Prop({ default: '' }) personName!: string
	@Prop({ default: '' }) storeName!: string
}
