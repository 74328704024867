import { isEmpty } from 'lodash'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import RequestType from '@microservice/Common/Search/Type/RequestType'
import { Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator'

@Module({
	name: 'Module/SearchModule',
	namespaced: true,
	stateFactory: true
})
export default class SearchModule extends VuexModule {

	static keyPhraseQSKey = 'keyphrase'

	keyPhrase: string|null = null

	get getKeyPhrase(): string {
		return this.keyPhrase || ''
	}

	get getSearchLink(): string {
		return '/search'
	}

	@VuexAction
	async updateFromRequest(
		{ pathBuilderMD, query } : {
			pathBuilderMD: PathBuilderModule,
			query: RequestType
		}
	): Promise<void> {
		this.setKeyPhrase({ pathBuilderMD, value: query.keyphrase || null })
	}

	@VuexMutation
	setKeyPhrase({ pathBuilderMD, value } : { pathBuilderMD: PathBuilderModule, value: string|null }) {
		if (isEmpty(value) || '' === value || null === value) {
			pathBuilderMD.clearQueryStringByKey(SearchModule.keyPhraseQSKey)
			this.keyPhrase = null
		} else {
			pathBuilderMD.setQueryStringByKey({ key: SearchModule.keyPhraseQSKey, value })
			this.keyPhrase = value
		}
	}

}
