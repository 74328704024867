






























































import { Component, Vue } from 'nuxt-property-decorator'
import { faPaypal } from '@fortawesome/free-brands-svg-icons'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons'

@Component({
	name: 'MerchantRegisterUSPs',
	components: {
		FedraLink
	}
})
export default class MerchantRegisterUSPs extends Vue {

	payPalIcon: IconDefinition = faPaypal
	bankIcon: IconDefinition = faBuildingColumns

}
