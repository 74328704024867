
















import { Component, Vue } from 'nuxt-property-decorator'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import MerchantBasePage from '@client/pages/Merchant/MerchantBasePage.vue'
import { faCode, faRobot } from '@fortawesome/free-solid-svg-icons'
import MerchantLoginOrRegisterButton from '@client/components/Merchant/MerchantLoginOrRegisterButton'
import MerchantFeedXMLSectionFedraBot from '@client/components/Merchant/MerchantFeedXMLSectionFedraBot.vue'
import MerchantFeedXMLSectionSchemaFields from '@client/components/Merchant/MerchantFeedXMLSectionSchemaFields.vue'
import MerchantRegistrationInvitationBanner from '@client/components/Merchant/MerchantRegistrationInvitationBanner.vue'
import MerchantFeedXMLHeaderBurgerNavigation from '@client/components/Merchant/MerchantFeedXMLHeaderBurgerNavigation.vue'
import MerchantFeedXMLSectionSupportedXMLSchemas from '@client/components/Merchant/MerchantFeedXMLSectionSupportedXMLSchemas.vue'

@Component({
	name: 'MerchantXMLPage',
	extends: MerchantBasePage,
	watchQuery: [],
	components: {
		MerchantFeedXMLSectionFedraBot,
		MerchantFeedXMLSectionSchemaFields,
		MerchantFeedXMLSectionSupportedXMLSchemas,
		MerchantRegistrationInvitationBanner,
		MerchantFeedXMLHeaderBurgerNavigation,
		MerchantLoginOrRegisterButton,
		FedraLink
	},
	layout: 'merchant'
})
export default class MerchantXMLPage extends MerchantBasePage {

	codeIcon: IconDefinition = faCode
	botIcon: IconDefinition = faRobot

	mounted() {
		const navigationClass = Vue.extend(MerchantFeedXMLHeaderBurgerNavigation)
		this.$parent.$emit('updateAdditionalHeaderComponent', navigationClass)
	}

}
