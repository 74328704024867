import { BaseEntity } from '@client/store/Common/BaseEntity'

export default class Collectable<T extends BaseEntity> {
	public items: T[]
	public count: number

	constructor(items?: T[]) {
		this.items = items
		this.count = items ? items.length : 0
	}

	collect<Key extends keyof T>(key: Key): T[Key][] {
		return this.items.map((item) => {
			return item[key]
		})
	}

	toJSON(): string {
		return JSON.stringify({
			items: this.items,
			count: this.count
		})
	}
}
