















import { trimStart } from 'lodash'
import { Component, getModule, Prop, Vue } from 'nuxt-property-decorator'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'

@Component({
	name: 'FedraLink'
})
export default class FedraLink extends Vue {
	externalSourcesMD: ExternalSourcesModule

	@Prop({ type: Boolean, default: false, required: false }) readonly appendMode!: boolean
	@Prop({ type: Boolean, default: false, required: false }) readonly noSsr!: boolean
	@Prop({ type: String, default: null, required: false }) readonly cssClass!: string
	@Prop({ type: String, default: '', required: true }) readonly to!: string
	@Prop({ default: null, required: false }) readonly target!: string
	@Prop({ default: null, required: false }) readonly title!: string
	@Prop({ default: null, required: false }) readonly rel!: string

	get getAppendMode(): boolean {
		if (this.appendMode)
			return true

		return this.isDestinationRelative
	}

	get isDestinationRelative(): boolean {
		return (!this.to.startsWith('/'))
	}

	get getDestination(): string {
		if (this.isDestinationRelative)
			return `${this.to}`

		const destination = trimStart( (this.to).replace(/([^:]\/)\/+/g, '$1'), '/')

		return (`${this.getLinkPrefix}/${destination}`)
	}

	get getLinkPrefix(): string {
		if (this.externalSourcesMD.getIsLive)
			return ''

		return `/${this.$i18n.locale}`
	}

	created(): void {
		this.externalSourcesMD = getModule(ExternalSourcesModule, this.$store)
	}
}
