






import { Component } from 'nuxt-property-decorator'
import MerchantBasePage from '@client/pages/Merchant/MerchantBasePage.vue'

@Component({
	name: 'MerchantLoginPage',
	key: route => route.fullPath,
	watchQuery: [],
	components: {},
	extends: MerchantBasePage,
	layout: 'merchant'
})
export default class MerchantLoginPage extends MerchantBasePage {

}
