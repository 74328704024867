

























import LazyHydrate from 'vue-lazy-hydration'
import Product from '@client/store/Domain/Product/Product'
import ProductModule from '@client/store/Module/ProductModule'
import Pagination from '@client/components/Listing/Pagination.vue'
import { Component, Vue, getModule } from 'nuxt-property-decorator'
import ProductCard from '@client/components/Product/ProductCard.vue'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import SortingOptions from '@client/components/Listing/SortingOptions.vue'
import CheckedFilters from '@client/components/Filters/CheckedFilters.vue'
import ProductFiltersModule from '@client/store/Module/ProductFiltersModule'

@Component({
	components: { LazyHydrate, SortingOptions, ProductCard, Pagination, CheckedFilters }
})
export default class ProductListing extends Vue {
	productMD: ProductModule
	productFiltersMD: ProductFiltersModule
	isInitialized: boolean = false

	get getProducts(): Product[] {
		return this.productMD.collection.items
	}

	get getFiltersIcon(): faFilter {
		return faFilter
	}

	toggleProductFilters(): void {
		this.productFiltersMD.toggleIsDisplayingFiltersSidebar()
	}

	created(): void {
		this.productMD = getModule(ProductModule, this.$store)
		this.productFiltersMD = getModule(ProductFiltersModule, this.$store)
	}

	mounted(): void {
		if (process.client)
			this.isInitialized = true
	}
}
