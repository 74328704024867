


































import { Component, getModule, Vue } from 'nuxt-property-decorator'
import PriceRangeModule from '@client/store/Module/PriceRangeModule'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import MinPriceHistogram from '@client/components/Charts/MinPriceHistogram'

@Component({
	name: 'PriceRangeFilter',
	components: { MinPriceHistogram }
})
export default class PriceRangeFilter extends Vue {
	priceRangeMD: PriceRangeModule
	pathBuilderMD: PathBuilderModule

	setMinPriceFrom(value: string|number) {
		this.priceRangeMD.setMinPriceFrom({ pathBuilderMD: this.pathBuilderMD, value })
	}
	setMinPriceTo(value: string|number) {
		this.priceRangeMD.setMinPriceTo({ pathBuilderMD: this.pathBuilderMD, value })
	}

	get getMinPriceFrom(): number|null {
		return this.priceRangeMD.getMinPriceFrom
	}
	get getMinPriceTo(): number|null {
		return this.priceRangeMD.getMinPriceTo
	}

	created(): void {
		this.priceRangeMD = getModule(PriceRangeModule, this.$store)
		this.pathBuilderMD = getModule(PathBuilderModule, this.$store)
	}

}
