export class BreadCrumbsObject {
	parent: BreadCrumbsItemObject
	children: BreadCrumbsItemObject[]
	delimiter = '>'

	toJSON(): string {
		return JSON.stringify({
			parent: this.parent,
			children: this.children,
			delimiter: this.delimiter
		})
	}
}

export class BreadCrumbsItemObject {
	link: string
	text: string

	constructor(link?: string, text?: string) {
		this.link = link || ''
		this.text = text || ''
	}
}

export class CategoryBreadCrumbs {
	id: number
	slug: string
	text: string
}
