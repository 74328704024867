








import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import MerchantLoginOrRegisterButton from '@client/components/Merchant/MerchantLoginOrRegisterButton'

@Component({
	name: 'MerchantHeaderNavigation',
	components: { FedraLink, MerchantLoginOrRegisterButton }
})
export default class MerchantHeaderNavigation extends Vue {

	navigationOpen: boolean = false

	@Prop({ default: true, type: Boolean }) isMobile!: boolean

	@Emit('navigation-opened')
	openNavigation() {
		this.navigationOpen = true
	}

	@Emit('navigation-closed')
	closeNavigation() {
		this.navigationOpen = false
	}

	created() {
		this.$on('navigation-open', this.openNavigation)
		this.$on('navigation-close', this.closeNavigation)
		this.$on('header-burger-open', this.openNavigation)
		this.$on('header-burger-close', this.closeNavigation)
	}

}
