


















































import { map } from 'lodash'
import VueHorizontal from 'vue-horizontal'
import LazyHydrate from 'vue-lazy-hydration'
import BrandModule from '@client/store/Module/BrandModule'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { Component, getModule, Vue } from 'nuxt-property-decorator'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import PriceRangeModule from '@client/store/Module/PriceRangeModule'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import ActiveFiltersModule from '@client/store/Module/ActiveFiltersModule'
import ProductFeatureModule from '@client/store/Module/ProductFeatureModule'
import { ProductFeature } from '@client/store/Domain/Feature/ProductFeature'
import TagCloudItem from '@client/store/Domain/Support/Filter/TagCloudItem'
import PathValueObject from '@client/store/Domain/Support/PathBuilder/PathValueObject'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons/faChevronCircleLeft'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight'
import PathSegmentValueObject from '@client/store/Domain/Support/PathBuilder/PathSegmentValueObject'
import QueryStringValueObject from '@client/store/Domain/Support/PathBuilder/QueryStringValueObject'

@Component({
	name: 'CheckedFilters',
	components: { VueHorizontal, FedraLink, LazyHydrate }
})
export default class CheckedFilters extends Vue {

	brandsMD: BrandModule
	priceRangeMD: PriceRangeModule
	pathBuilderMD: PathBuilderModule
	activeFiltersMD: ActiveFiltersModule
	productFeatureMD: ProductFeatureModule

	hasPrev: boolean = false
	hasNext: boolean = true
	tagsArrowsHidden: boolean = true

	closeIcon: IconDefinition = faTimes
	leftArrowIcon: IconDefinition = faChevronCircleLeft
	rightArrowIcon: IconDefinition = faChevronCircleRight

	$refs!: {
		vueHorizontalTags: HTMLElement,
	}

	get getSelectedBrandFilterIds(): number[] {
		return this.activeFiltersMD.getSelectedBrandIds
	}

	get getSelectedFeatureFilterIds(): number[] {
		return this.activeFiltersMD.getSelectedFeatureIds
	}

	get getClearingAllTag(): TagCloudItem {
		const path = new PathValueObject(this.pathBuilderMD.getPath)
		return new TagCloudItem({
			name: this.$t('constants.clear_all'),
			url: path
				.setPathSegment('brand', new PathSegmentValueObject({}))
				.setPathSegment('feature', new PathSegmentValueObject({}))
				.setQueryString(new QueryStringValueObject())
				.build
		})
	}

	get getTags(): TagCloudItem[] {
		const checkedFilters = []
		checkedFilters.push(...this.brandsMD.getSelectedTags(this.getSelectedBrandFilterIds))
		checkedFilters.push(...this.productFeatureMD.getSelectedTags(this.getSelectedFeatureFilterIds))
		checkedFilters.push(...map(this.priceRangeMD.getSelectedTags(), (tag) => {
			return new TagCloudItem({
				name: `${this.$t('constants_commons.price')} ${tag.name}`,
				url: tag.url
			})
		}))
		return checkedFilters
	}

	get getTagsContentHtmlElementWidth(): number {
		return this.$refs.vueHorizontalTags.$el.children[0].scrollWidth
	}

	get getTagsContentHtmlElementMaxWidth(): number {
		return this.$refs.vueHorizontalTags.$el.children[0].getBoundingClientRect().width
	}

	public vueHorizontalTagsArrowsHidden(): void {
		if (this.getTagsContentHtmlElementWidth > this.getTagsContentHtmlElementMaxWidth) {
			this.tagsArrowsHidden = false
		} else if (this.getTagsContentHtmlElementWidth <= this.getTagsContentHtmlElementMaxWidth) {
			this.tagsArrowsHidden = true
		}
	}

	mounted(): void {
		this.vueHorizontalTagsArrowsHidden()
	}

	created(): void {
		this.brandsMD = getModule(BrandModule, this.$store)
		this.productFeatureMD = getModule(ProductFeatureModule, this.$store)
		this.priceRangeMD = getModule(PriceRangeModule, this.$store)
		this.activeFiltersMD = getModule(ActiveFiltersModule, this.$store)
		this.pathBuilderMD = getModule(PathBuilderModule, this.$store)
	}

	public vueHorizontalTagsPrev(): void {
		this.$refs.vueHorizontalTags.prev()
	}

	public vueHorizontalTagsNext(): void {
		this.$refs.vueHorizontalTags.next()
	}

	public vueHorizontalTagsScroll({ hasPrev, hasNext }) {
		this.hasPrev = hasPrev
		this.hasNext = hasNext
	}

}
