import { upperCase } from 'lodash'

export default function({ $gtm, i18n }) {
	if ('true' === process.env.IS_GTM_ENABLED) {
		const targetGTMKey = `${upperCase(i18n.locale)}_GOOGLE_TAG_MANAGER_ID`
		process.env.GOOGLE_TAG_MANAGER_ID = process.env.DEFAULT_GOOGLE_TAG_MANAGER_ID
		if (targetGTMKey in process.env) {
			process.env.GOOGLE_TAG_MANAGER_ID = process.env[targetGTMKey]
		}
		$gtm.init(process.env.GOOGLE_TAG_MANAGER_ID)
	}
}
