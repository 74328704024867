import { map } from 'lodash'
import { BaseEntity } from '@client/store/Common/BaseEntity'
import { CategoryTreeChild } from '@microservice/Category/Type/CategoriesTreeType'
import CategoryDisplayOption from '@microservice/Category/API/Enum/CategoryDisplayOption'

export default class Category extends BaseEntity {
	category_id: number
	parent_id: number
	title: string
	is_sensitive: boolean
	is_valid: boolean
	display_options: CategoryDisplayOption[]
	slug: string
	link: string
	count: number
	children?: Category[]

	constructor(props: Partial<Category> | CategoryTreeChild) {
		super()
		if (!props) return
		this.category_id = props.category_id || 0

		Object.assign(this, props)
		if ('children' in this) {
			this.children = map(this.children, (child) => {
				return new Category(child)
			})
		}
	}

	setLink(link: string): void {
		this.link = link
	}
}
