














import { Component, Vue } from 'nuxt-property-decorator'
import MerchantFeedXMLSection from '@client/components/Merchant/MerchantFeedXMLSection.vue'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faTable } from '@fortawesome/free-solid-svg-icons'

@Component({
	name: 'MerchantFeedXMLSectionSchemaFields',
	components: { MerchantFeedXMLSection },
})
export default class MerchantFeedXMLSectionSchemaFields extends Vue {

	tableIcon: IconDefinition = faTable

}

