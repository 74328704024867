

















import { Store } from 'vuex'
import { NuxtI18nInstance } from '@nuxtjs/i18n'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { NuxtAppOptions, NuxtError } from '@nuxt/types'
import PageMeta from '@microservice/Nuxtgen/API/DTO/PageMeta'
import { Component, Vue, getModule } from 'nuxt-property-decorator'
import SearchPageRefreshModule from '../store/Module/SearchPageRefreshModule'
import RequestType from '@microservice/Common/Search/Type/RequestType'
import Breadcrumbs from '@client/components/Breadcrumbs/Breadcrumbs.vue'
import CategoryBanner from '@client/components/Category/CategoryBanner.vue'
import CategoryHeader from '@client/components/Category/CategoryHeader.vue'
import CategoryListing from '@client/components/Category/CategoryListing.vue'
import CategoryListingSmall from '@client/components/Category/CategoryListingSmall.vue'
import FedraNuxtAppContext from '@client/store/Domain/Support/PageRefresh/FedraNuxtAppContext'
import ProductListingWithFilters from '@client/components/Product/ProductListingWithFilters.vue'

@Component({
	name: 'SearchPage',
	key: route => route.fullPath,
	watchQuery: ['page', 'page_size', 'sort', 'min_price_from', 'min_price_to', 'keyphrase'],
	components: {
		LazyCategoryBanner: CategoryBanner,
		LazyCategoryHeader: CategoryHeader,
		LazyCategoryListing: CategoryListing,
		LazyCategoryListingSmall: CategoryListingSmall,
		LazyBreadcrumbs: Breadcrumbs,
		LazyProductListingWithFilters: ProductListingWithFilters
	}
})
export default class SearchPage extends Vue {

	meta: PageMeta = new PageMeta()

	head() {
		return {
			htmlAttrs: {
				lang: this.$i18n.locale,
			},
			...this.meta,
		}
	}

	async asyncData(
		{
			app,
			params,
			$axios,
			store,
			i18n,
			query,
			error
		} : {
			app: NuxtAppOptions,
			params: Record<string, any>,
			$axios: NuxtAxiosInstance,
			store: Store<any>,
			i18n: NuxtI18nInstance,
			query: RequestType,
			error: NuxtError
		}
	) {
		const searchPageRefreshMD = getModule(SearchPageRefreshModule, store)
		try {
			return await searchPageRefreshMD.refresh(
				{
					app,
					params,
					$axios,
					store,
					i18n,
					query,
					error,
					process
				} as FedraNuxtAppContext
			)
		} catch (err: Error) {
			error({ message: err.message })
		}
	}

}
