import Brand from '@client/store/Domain/Brand/Brand'
import { Read } from '@client/store/Common/DataMapperContract'

export class BrandDM implements Read {
	responseDataToEntity(data: Partial<Brand>): Brand {
		return new Brand(data)
	}

	responseDataToCollection(data: Partial<Brand>[]): Brand[] {
		return data.map((item) => new Brand(item))
	}
}
