import { isEmpty, map } from 'lodash'
import Taggable from '@client/store/Domain/Support/Filter/Taggable'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import RequestType from '@microservice/Common/Search/Type/RequestType'
import TagCloudItem from '@client/store/Domain/Support/Filter/TagCloudItem'
import { Module, VuexMutation, VuexModule, VuexAction } from 'nuxt-property-decorator'
import PathValueObject from '@client/store/Domain/Support/PathBuilder/PathValueObject'
import PriceHistogramSamplePointDTO from '@microservice/Nuxtgen/API/DTO/PriceHistogramSamplePointDTO'

@Module({
	name: 'Module/PriceRangeModule',
	namespaced: true,
	stateFactory: true
})
export default class PriceRangeModule extends VuexModule implements Taggable<any> {
	minPriceFrom: number = null
	minPriceFromLink = ''
	minPriceTo: number = null
	minPriceToLink = ''
	minPriceHistogram: PriceHistogramSamplePointDTO[]

	get getSelectedTags(): () => TagCloudItem[] {
		return () => {
			const tagClouds: TagCloudItem[] = []
			if (null !== this.getMinPriceFrom) {
				tagClouds.push(new TagCloudItem({
					name: `> ${this.getMinPriceFrom}`,
					url: this.minPriceFromLink
				}))
			}
			if (null !== this.getMinPriceTo) {
				tagClouds.push(new TagCloudItem({
					name: `< ${this.getMinPriceTo}`,
					url: this.minPriceToLink
				}))
			}
			return tagClouds
		}
	}

	get getMinPriceFrom(): number|null {
		return this.minPriceFrom
	}

	get getMinPriceTo(): number|null {
		return this.minPriceTo
	}

	get getMinPriceHistogramSeries(): number[] {
		return map(this.minPriceHistogram, point => point.product_count)
	}

	@VuexAction
	async updateFromRequest({ pathBuilderMD, query } : { pathBuilderMD: PathBuilderModule, query: RequestType }): Promise<void> {
		if ('min_price_from' in query) {
			this.setMinPriceFrom({ pathBuilderMD, value: query.min_price_from })
		} else {
			this.setMinPriceFrom({ pathBuilderMD, value: null })
		}

		if ('min_price_to' in query) {
			this.setMinPriceTo({ pathBuilderMD, value: query.min_price_to })
		} else {
			this.setMinPriceTo({ pathBuilderMD, value: null })
		}
	}

	@VuexAction({ rawError: true })
	async updateFromResponse({ pathBuilderMD } : { pathBuilderMD: PathBuilderModule }): Promise<void> {
		const path = new PathValueObject(pathBuilderMD.getPath)

		if (null !== this.minPriceFrom) {
			this.setMinPriceFromLink(path.setQueryString(path.querystring.clearQuery('min_price_from')).build)
		} else {
			this.setMinPriceFromLink(path.build)
		}

		if (null !== this.minPriceTo) {
			this.setMinPriceToLink(path.setQueryString(path.querystring.clearQuery('min_price_to')).build)
		} else {
			this.setMinPriceToLink(path.build)
		}
	}

	@VuexMutation
	setMinPriceHistogram(minPriceHistogram: PriceHistogramSamplePointDTO[]): void {
		this.minPriceHistogram = minPriceHistogram
	}

	@VuexMutation
	setMinPriceFrom({ pathBuilderMD, value } : { pathBuilderMD: PathBuilderModule, value: number|string }): void {
		if (isEmpty(value) || isNaN(Number(value))) {
			pathBuilderMD.clearQueryStringByKey('min_price_from')
			this.minPriceFrom = null
			return
		}

		value = Number(value)
		this.minPriceFrom = value
		pathBuilderMD.setQueryStringByKey({ key: 'min_price_from', value: String(value) })

		if (value > this.minPriceTo && null !== this.minPriceTo) {
			this.minPriceTo = value
			pathBuilderMD.setQueryStringByKey({ key: 'min_price_to', value: String(value) })
		}
	}

	@VuexMutation
	setMinPriceTo({ pathBuilderMD, value } : { pathBuilderMD: PathBuilderModule, value: number|string }): void {
		if (isEmpty(value) || isNaN(Number(value))) {
			pathBuilderMD.clearQueryStringByKey('min_price_to')
			this.minPriceTo = null
			return
		}

		value = Number(value)

		this.minPriceTo = value
		pathBuilderMD.setQueryStringByKey({ key: 'min_price_to', value: String(value) })

		if (value < this.minPriceFrom && null !== this.minPriceFrom) {
			this.minPriceFrom = value
			pathBuilderMD.setQueryStringByKey({ key: 'min_price_to', value: String(value) })
		}
	}

	@VuexMutation
	setMinPriceFromLink(link: string): void {
		this.minPriceFromLink = link
	}

	@VuexMutation
	setMinPriceToLink(link: string): void {
		this.minPriceToLink = link
	}

}
