








































import ProductModule from '@client/store/Module/ProductModule'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { Component, getModule, Vue } from 'nuxt-property-decorator'
import NoScript from '@client/components/Utilities/NoScript.vue'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'
import ProductReviewRating from '@client/components/Product/ProductReviewRating.vue'

@Component({
	props: ['product'],
	components: { FedraLink, ProductReviewRating, NoScript }
})
export default class ProductCard extends Vue {
	productsMD: ProductModule
	externalSourcesMD: ExternalSourcesModule

	get hasFeatures() {
		return (this.$props.product.features.length !== 0) ? '✓' : ''
	}

	get getMediaStreamLocation(): string {
		return this.externalSourcesMD.mediaStreamLocation
	}

	created(): void {
		this.productsMD = getModule(ProductModule, this.$store)
		this.externalSourcesMD = getModule(ExternalSourcesModule, this.$store)
	}
}
