
import { Store } from 'vuex'
import { NuxtI18nInstance } from '@nuxtjs/i18n'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { NuxtAppOptions, NuxtError } from '@nuxt/types'
import { Vue, Component, getModule } from 'nuxt-property-decorator'
import RequestType from '@microservice/Common/Search/Type/RequestType'
import StaticPageRefreshModule from '@client/store/Module/StaticPageRefreshModule'
import FedraNuxtAppContext from '@client/store/Domain/Support/PageRefresh/FedraNuxtAppContext'

@Component({
	name: 'StaticBasePage'
})
export default class StaticBasePage extends Vue {

	async asyncData(
		{
			app,
			params,
			$axios,
			store,
			i18n,
			query,
			error
		} : {
			app: NuxtAppOptions,
			params: Record<string, any>,
			$axios: NuxtAxiosInstance,
			store: Store<any>,
			i18n: NuxtI18nInstance,
			query: RequestType,
			error: NuxtError
		}
	) {
		const staticPageRefreshMD = getModule(StaticPageRefreshModule, store)
		try {
			return await staticPageRefreshMD.refresh(
				{
					app,
					params,
					$axios,
					store,
					i18n,
					query,
					error,
					process
				} as FedraNuxtAppContext
			)
		} catch (err: Error) {
			// @ts-ignore
			error({ message: err.message })
		}
	}

}

