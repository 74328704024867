import { cloneDeep, each, intersection } from 'lodash'
import PageMetaLink from '@microservice/Nuxtgen/API/DTO/PageMetaLink'

export default class PageMeta {

	title: string
	titleClean: string
	hasFilters: boolean
	isIndexable: boolean
	titleTemplate: string
	isBrandCanonical: boolean
	isProductFeatureValueCanonical: boolean
	meta: [
		{ hid: 'description', name: 'description', content: string },
		{ property: 'og:url', content: string },
		{ property: 'og:type', content: string },
		{ property: 'og:title', content: string },
		{ property: 'og:description', content: string },
		{ name: 'robots', content: string },
		{ name: 'twitter:card', content: string },
		{ name: 'twitter:domain', content: string },
		{ name: 'twitter:title', content: string },
		{ name: 'twitter:description', content: string },
	]
	link: Array<PageMetaLink>
	script: Array<Record<string|number, unknown>> = []

	findMetaIndexesWhere(keyMap: string[], valueMap: string[]): number[] {
		const indexes = []
		each(this.meta, (metaRecord, idx: number) => {
			const intersectedKeys = intersection(Object.keys(metaRecord), keyMap)
			const intersectedVals = intersection(Object.values(metaRecord), valueMap)
			if (intersectedKeys.length > 0 && intersectedVals.length > 0)
				indexes.push(idx)
		})
		return indexes
	}

	setTitle(title: string): PageMeta {
		const meta = cloneDeep(this)
		meta.title = title
		const metaIndexes = this.findMetaIndexesWhere(['property', 'name'], ['og:title', 'twitter:title'])
		each(metaIndexes, metaIndex => meta.meta[metaIndex].content = title)
		return meta
	}

	setDescription(description: string): PageMeta {
		const meta = cloneDeep(this)
		const metaIndexes = this.findMetaIndexesWhere(
			['hid', 'property', 'name'],
			['description', 'og:description', 'twitter:description']
		)
		each(metaIndexes, metaIndex => meta.meta[metaIndex].content = description)
		return meta
	}

	setOGTypeContent(content: string): PageMeta {
		const meta = cloneDeep(this)
		const metaIndexes = this.findMetaIndexesWhere(['property'], ['og:type'])
		each(metaIndexes, metaIndex => meta.meta[metaIndex].content = content)
		return meta
	}

	setTwitterCardContent(content: string): PageMeta {
		const meta = cloneDeep(this)
		const metaIndexes = this.findMetaIndexesWhere(['name'], ['twitter:card'])
		each(metaIndexes, metaIndex => meta.meta[metaIndex].content = content)
		return meta
	}

	setUrlContent(content: string): PageMeta {
		const meta = cloneDeep(this)
		const metaIndexes = this.findMetaIndexesWhere(['property', 'name'], ['og:url', 'twitter:domain'])
		each(metaIndexes, metaIndex => meta.meta[metaIndex].content = content)
		return meta
	}

	setCanonicalUrl(canonicalUrl: string): PageMeta {
		const meta = cloneDeep(this)
		meta.link = [{
			rel: 'canonical',
			href: canonicalUrl
		}]
		return meta
	}

	setRobotsContent(content: string): PageMeta {
		const meta = cloneDeep(this)
		const metaIndexes = this.findMetaIndexesWhere(['name'], ['robots'])
		each(metaIndexes, metaIndex => meta.meta[metaIndex].content = content)
		return meta
	}

	setHasFilters(hasFilters: boolean): PageMeta {
		const meta = cloneDeep(this)
		meta.hasFilters = hasFilters
		return meta
	}

	setIsIndexable(isIndexable: boolean): PageMeta {
		const meta = cloneDeep(this)
		meta.isIndexable = isIndexable
		return meta
	}

	setIsBrandCanonical(isBrandCanonical: boolean): PageMeta {
		const meta = cloneDeep(this)
		meta.isBrandCanonical = isBrandCanonical
		return meta
	}

	setisProductFeatureValueCanonical(isProductFeatureValueCanonical: boolean): PageMeta {
		const meta = cloneDeep(this)
		meta.isProductFeatureValueCanonical = isProductFeatureValueCanonical
		return meta
	}

	appendRichResult(json: unknown): PageMeta {
		const meta = cloneDeep(this)
		meta.script.push({ type: 'application/ld+json', json })
		return meta
	}
	
	appendCustomScript(scriptData: Record<string, string|number|boolean>): PageMeta {
		const meta = cloneDeep(this)
		meta.script.push(scriptData)
		return meta
	}

	constructor(data?: Partial<PageMeta>) {
		this.meta = [
			{ hid: 'description', name: 'description', content: '' },
			{ property: 'og:url', content: '' },
			{ property: 'og:type', content: '' },
			{ property: 'og:title', content: '' },
			{ property: 'og:description', content: '' },
			{ name: 'robots', content: 'nofollow,noindex' },
			{ name: 'twitter:card', content: '' },
			{ name: 'twitter:domain', content: '' },
			{ name: 'twitter:title', content: '' },
			{ name: 'twitter:description', content: '' },
		]
		Object.assign(this, data)
	}

	toJSON(): PageMeta {
		return this
	}
}