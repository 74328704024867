import { isEmpty, get } from 'lodash'

export default class PathSegmentValueObject {
	prefix: string
	slugs: string[]
	IDs: number[]

	constructor(data?: Partial<PathSegmentValueObject>) {
		this.prefix = get(data, 'prefix', '')
		this.slugs = get(data, 'slugs', [])
		this.IDs = get(data, 'IDs', [])
	}

	get path(): string {
		if (this.IDs.length === 0) return ''
		const IDs = this.IDs.join('_')
		const slugs = this.slugs.join('_or_')
		if (isEmpty(IDs.trim()))
			return ''

		return `/${this.prefix}/${IDs}/${slugs}`
	}

	toJSON(): Record<string, string | string[] | number[]> {
		return {
			prefix: this.prefix,
			slugs: this.slugs,
			IDs: this.IDs
		}
	}
}
