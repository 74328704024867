import { get, cloneDeep } from 'lodash'
import PathSegmentValueObject from '@client/store/Domain/Support/PathBuilder/PathSegmentValueObject'
import QueryStringValueObject from '@client/store/Domain/Support/PathBuilder/QueryStringValueObject'

export default class PathValueObject {
	public category: PathSegmentValueObject
	public brand: PathSegmentValueObject
	public feature: PathSegmentValueObject
	public querystring: QueryStringValueObject

	constructor(data?: Partial<PathValueObject>) {
		this.category = new PathSegmentValueObject(get(data, 'category', {}))
		this.brand = new PathSegmentValueObject(get(data, 'brand', {}))
		this.feature = new PathSegmentValueObject(get(data, 'feature', {}))
		this.querystring = new QueryStringValueObject(get(data, 'querystring', {}))
	}

	get getCategoryPath(): string {
		return get(this, ['category', 'path'], '')
	}

	setPathSegment(pathSegmentKey: keyof PathValueObject, pathSegment: PathSegmentValueObject): PathValueObject {
		const pathVO = cloneDeep(this)
		// @ts-ignore
		pathVO[pathSegmentKey] = pathSegment
		return pathVO
	}

	get getBrandPath(): string {
		return get(this, ['brand', 'path'], '')
	}

	get getFeaturePath(): string {
		return get(this, ['feature', 'path'], '')
	}

	get getQueryStringPath(): string {
		return get(this, ['querystring', 'path'], '')
	}

	setQueryString(queryString: QueryStringValueObject): PathValueObject {
		const pathVO = cloneDeep(this)
		pathVO.querystring = queryString
		return pathVO
	}

	get build(): string {
		return `${this.getCategoryPath}${this.getBrandPath}${this.getFeaturePath}${this.getQueryStringPath}`
	}

	toJSON(): string {
		return JSON.stringify({
			category: this.category,
			brand: this.brand,
			feature: this.feature,
			querystring: this.querystring
		})
	}
}
