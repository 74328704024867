




















import { get } from 'lodash'
import { Component } from 'nuxt-property-decorator'
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import LoginOrRegisterButton from '@client/components/LoginOrRegisterButton'

@Component({
	name: 'MerchantLoginOrRegisterButton',
	components: { FedraLink }
})
export default class MerchantLoginOrRegisterButton extends LoginOrRegisterButton {

	registerIcon: IconDefinition = faPen
	signInIcon: IconDefinition = faUser
	merchantLoginUrl: string = '/merchant/merchant_login.php/'
	merchantRegisterUrl: string = '/merchant/merchant_register'

	get shouldTreatAsExternal(): boolean {
		// the login url should not be treated as internal link as it is not fully implemented in nuxt get
		return this.getIsMerchantRegisterPage
	}

	get getIsMerchantRegisterPage() {
		const routeId = get(this.$route, 'meta.routeId', '')
		return routeId == 'MerchantRegisterPage'
	}

	get getLoginOrRegisterButtonUrl(): string {
		if (this.getIsMerchantRegisterPage) {
			return this.merchantLoginUrl
		}
		return this.merchantRegisterUrl
	}

	get getLoginOrRegisterButtonTitle(): string {
		if (this.getIsMerchantRegisterPage) {
			return this.$t('constants_commons.login') as string
		}
		return this.$t('constants_commons.signup') as string
	}

	getLoginOrRegisterButtonIcon(): IconDefinition {
		if (this.getIsMerchantRegisterPage) {
			return this.signInIcon;
		}
		return this.registerIcon;
	}

	getLoginOrRegisterButtonText(): string {
		if (this.getIsMerchantRegisterPage) {
			return this.$t('constants_commons.login') as string
		}
		return this.$t('constants_commons.signup') as string
	}

}
