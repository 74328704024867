





















import { lowerCase, upperFirst } from 'lodash'
import Category from '@client/store/Domain/Category/Category'
import ProductModule from '@client/store/Module/ProductModule'
import PageMetaModule from '@client/store/Module/PageMetaModule'
import CategoryModule from '@client/store/Module/CategoryModule'
import { Component, getModule, Vue } from 'nuxt-property-decorator'
import PaginationModule from '@client/store/Module/PaginationModule'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'
import CategoryDisplayOption from '@microservice/Category/API/Enum/CategoryDisplayOption'

@Component({
	name: 'CategoryBanner'
})
export default class CategoryBanner extends Vue {

	productMD: ProductModule
	categoryMD: CategoryModule
	pageMetaMD: PageMetaModule
	paginationMD: PaginationModule
	externalSourcesMD: ExternalSourcesModule

	get getPageMetaTitle(): string {
		return this.pageMetaMD.getPageMeta.titleClean
	}

	get getCategoryHeaderMain(): string {
		return this.getPageMetaTitle
	}

	get getPageTitle(): string {
		if (this.getPageNum < 2) return ''
		return `(${this.$t('constants_commons.page')} ${this.getPageNum})`
	}

	get getPageNum(): number {
		return this.paginationMD.getPage
	}

	get getCategory(): Category {
		return this.categoryMD.collection.parent
	}

	get getMediaStreamLocation(): string {
		return this.externalSourcesMD.mediaStreamLocation
	}

	get supportsBannerDisplay(): boolean {
		return this.categoryMD.doesParentSupportDisplayOption(CategoryDisplayOption.BANNER)
	}

	get getCategoryBannerLabel(): string {
		return upperFirst(lowerCase([
			this.$t('constants_commons.see'),
			this.getCategory.count,
			this.$t('constants.products'),
			this.$t('constants_commons.from'),
			this.productMD.getStoreIdCardinality,
			this.$t('listing_product_card.listing_product_card_stores')
		].join(' ')))
	}

	created () {
		this.productMD = getModule(ProductModule, this.$store)
		this.categoryMD = getModule(CategoryModule, this.$store)
		this.pageMetaMD = getModule(PageMetaModule, this.$store)
		this.paginationMD = getModule(PaginationModule, this.$store)
		this.externalSourcesMD = getModule(ExternalSourcesModule, this.$store)
	}

}
