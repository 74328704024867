

































import LazyHydrate from 'vue-lazy-hydration'
import Brand from '@client/store/Domain/Brand/Brand'
import BrandModule from '@client/store/Module/BrandModule'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { Component, Vue, getModule } from 'nuxt-property-decorator'
import ActiveFiltersModule from '@client/store/Module/ActiveFiltersModule'

@Component({
	name: 'BrandsFilter',
	components: { FedraLink, LazyHydrate }
})
export default class BrandsFilter extends Vue {
	brandsMD: BrandModule
	activeFiltersMD: ActiveFiltersModule

	get getShouldBrandFiltersBeDisplayed(): boolean {
		return this.brandsMD.getShouldBrandFiltersBeDisplayed
	}

	get markerIcon(): faCheck {
		return faCheck
	}

	get getSelected(): number[] {
		return this.activeFiltersMD.getSelectedBrandIds
	}

	get getCollectionChecked(): Brand[] {
		return this.brandsMD.getCollectionChecked(this.getSelected)
	}

	get getCollectionUnChecked(): Brand[] {
		return this.brandsMD.getCollectionUnChecked(this.getSelected)
	}

	getSelectedClass(brandID: number): 'selected'|'' {
		return this.getSelected.includes(brandID) ? 'selected' : ''
	}

	created(): void {
		this.brandsMD = getModule(BrandModule, this.$store)
		this.activeFiltersMD = getModule(ActiveFiltersModule, this.$store)
	}
}
