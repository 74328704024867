import Collectable from '@client/store/Common/Collectable'
import { Module, VuexMutation, VuexModule } from 'nuxt-property-decorator'
import { ProductFeatureValue } from '@client/store/Domain/Feature/ProductFeatureValue'

@Module({
	name: 'Module/FeatureValueModule',
	namespaced: true,
	stateFactory: true
})
export default class FeatureValueModule extends VuexModule {
	collection = new Collectable<ProductFeatureValue>()

	get getCollection(): Collectable<ProductFeatureValue> {
		return this.collection
	}

	@VuexMutation
	setCollection(collection: ProductFeatureValue[]): void {
		this.collection = new Collectable<ProductFeatureValue>(collection)
	}
}
