import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=2bd017db&scoped=true&"
import script from "./PageHeader.vue?vue&type=script&lang=ts&"
export * from "./PageHeader.vue?vue&type=script&lang=ts&"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=2bd017db&lang=scss&scoped=true&"
import style1 from "./PageHeader.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bd017db",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AboutBurgerButton: require('/home/node/client/components/About/AboutBurgerButton.vue').default,SearchBar: require('/home/node/client/components/SearchBar/SearchBar.vue').default})
