














































import VueHorizontal from 'vue-horizontal'
import { Component, Vue } from 'nuxt-property-decorator'
import Number from 'vue-number-animation/Number.vue'
import FedraLottiePlayer from '@client/components/Utilities/FedraLottiePlayer.vue'
import * as stonks from '@client/assets/lotties/stonks.json'
import InViewportDirective from '@client/directives/inViewportDirective'

@Component({
	name: 'AboutFedraWorld',
	components: { VueHorizontal, Number, FedraLottiePlayer },
	directives: {
		inViewport: InViewportDirective
	}
})
export default class AboutFedraWorld extends Vue {

	$refs!: {
		anim1: HTMLElement
		anim2: HTMLElement
		anim3: HTMLElement
	}

	get getRatingAnim(): string {
		return stonks
	}

	public animateAllLotties(): void {
		window.setTimeout(() => {
			this.$refs.anim1.$emit('lottie-replay')
		}, 0)
		window.setTimeout(() => {
			this.$refs.anim2.$emit('lottie-replay')
		}, 100)
		window.setTimeout(() => {
			this.$refs.anim3.$emit('lottie-replay')
		}, 300)
	}

}

