import { get, map } from 'lodash'
import Nestable from '@client/store/Common/Nestable'
import Category from '@client/store/Domain/Category/Category'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import ActiveFiltersModule from '@client/store/Module/ActiveFiltersModule'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'
import SearchPageResults from '@microservice/Nuxtgen/API/DTO/SearchPageResults'
import ListItem from '@client/store/Domain/Support/RichResults/org.schema/ListItem'
import { Module, VuexMutation, VuexModule, VuexAction } from 'nuxt-property-decorator'
import { CategoriesTree, CategoryTreeChild } from '@microservice/Category/Type/CategoriesTreeType'
import { CategoryDM } from '@client/store/Domain/Category/DataMapper/CategoryDataMapper'
import CategoryDisplayOption from '@microservice/Category/API/Enum/CategoryDisplayOption'

@Module({
	name: 'Module/CategoryModule',
	namespaced: true,
	stateFactory: true
})
export default class CategoryModule extends VuexModule {
	collection = new Nestable<Category>()
	rootCollection = new Nestable<Category>()

	get getCollection(): Nestable<Category> {
		return this.collection
	}

	get getRootCollection(): Nestable<Category> {
		return this.rootCollection
	}

	get isCategoryParentRoot(): boolean {
		return this.collection.parent.category_id === 0
	}

	get doesParentSupportDisplayOption(): (displayOption: CategoryDisplayOption) => boolean {
		return (displayOption): boolean => {
			if (0 === this.collection.parent.category_id) {
				if (CategoryDisplayOption.PRODUCT_LISTING === displayOption) {
					return true
				}
			}

			const displayOptions = get(this.collection, 'parent.display_options', [])
			return displayOptions.includes(displayOption)
		}
	}

	@VuexAction
	async getStructuredData(externalSourcesMD: ExternalSourcesModule): Promise<ListItem> {
		const childItems: ListItem[] = map(
			this.collection.children,
			(item, idx) => new ListItem({
				'@type': 'ListItem',
				name: item.title,
				item: `${externalSourcesMD.getInstanceRoot}${item.link}`,
				position: idx + 1
			})
		)

		return new ListItem({
			'@context': 'https://schema.org',
			numberOfItems: childItems.length,
			itemListElement: childItems
		})
	}

	@VuexAction({ rawError: true })
	async prepareUpdateFromResponse(
		{ response, activeFiltersMD, pathBuilderMD } : {
			response: SearchPageResults,
			activeFiltersMD: ActiveFiltersModule,
			pathBuilderMD: PathBuilderModule
		}
	): Promise<void> {
		let categoryFilterDM = null
		try {
			categoryFilterDM = new CategoryDM().responseDataToEntity(response.filters.category)
		} catch (e) {
			// response category is empty or invalid
		} finally {
			activeFiltersMD.setCategory(categoryFilterDM)
			pathBuilderMD.setFilters(activeFiltersMD.getFilters)
		}
	}

	@VuexAction({ rawError: true })
	async updateFromResponse(
		{ response, pathBuilderMD } : {
			response: SearchPageResults,
			pathBuilderMD: PathBuilderModule
		}
	): Promise<void> {
		const collection = await this.updateCategoriesFromResponse({
			categories: response.categories,
			pathBuilderMD
		})
		this.setCollection(collection)

		const rootCollection = await this.updateCategoriesFromResponse({
			categories: response.rootCategories,
			pathBuilderMD
		})
		this.setRootCollection(rootCollection)
	}

	@VuexAction({ rawError: true })
	async updateCategoriesFromResponse(
		{ categories, pathBuilderMD } : {
			categories: CategoriesTree,
			pathBuilderMD: PathBuilderModule
		}
	): Promise<Nestable<Category>> {
		const categoriesDM = new CategoryDM().responseDataToCollection(categories.children as unknown as Partial<Category>[])
		const categoriesWithLinks = categoriesDM.map((category: Category) => {
			category = new Category(category)
			category.children = map(category.children, (child) => {
				child.setLink(pathBuilderMD.getCategoryUrl(child))
				return child
			})
			category.setLink(pathBuilderMD.getCategoryUrl(category))
			return category
		})
		categories.children = categoriesWithLinks as unknown as CategoryTreeChild[]
		return categories as unknown as Nestable<Category>
	}

	@VuexMutation
	setCollection(nested: Nestable<Category>): void {
		this.collection = new Nestable<Category>(nested.parent, nested.children)
	}

	@VuexMutation
	setRootCollection(nested: Nestable<Category>): void {
		this.rootCollection = new Nestable<Category>(nested.parent, nested.children)
	}
}
