import { defaultPageSize } from '@client/store/Module/PaginationModule'
import QueryStringValueObject from '@client/store/Domain/Support/PathBuilder/QueryStringValueObject'

export default class PaginationLink {
	name: string|number
	page: number
	page_size: number
	queryString: QueryStringValueObject
	selected = false

	constructor(data?: Partial<PaginationLink>) {
		Object.assign(this, data)

		if (!this.queryString)
			this.queryString = new QueryStringValueObject()

		this.queryString = this.queryString.setQuery('page', String(this.page))

		if (this.page_size != defaultPageSize)
			this.queryString = this.queryString.setQuery('page_size', String(this.page_size))
	}
}
