import { first, get } from 'lodash'
import { NuxtI18nInstance } from '@nuxtjs/i18n'
import Locale from '@microservice/Common/Search/Type/LocaleType'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'
import SearchPageResults from '@microservice/Nuxtgen/API/DTO/SearchPageResults'
import GenericPageParams from '@client/store/Domain/Support/PathBuilder/GenericPageParams'
import FedraNuxtAppContext from '@client/store/Domain/Support/PageRefresh/FedraNuxtAppContext'
import { getModule, Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator'

export class StaticPageContents {}

@Module({
	name: 'Module/StaticPageRefreshModule',
	namespaced: true,
	stateFactory: true
})
export default class StaticPageRefreshModule extends VuexModule {

	currentLocale: Locale = null

	@VuexAction({ rawError: true })
	async refresh(appContext: FedraNuxtAppContext): Promise<StaticPageContents> {
		const externalSourcesMD = getModule(ExternalSourcesModule, appContext.store)
		const params = appContext.params
		const i18n = appContext.i18n

		await externalSourcesMD.updateFromStatic(i18n.locale)
		await this.updatei18nFromParamOrRequest({ params, i18n })

		return {}
	}

	@VuexAction
	async updatei18nFromParamOrRequest(
		{ params, i18n }: {
			params: GenericPageParams,
			i18n: NuxtI18nInstance
		}
	): Promise<void> {
		if (!this.currentLocale)
			this.setCurrentLocale(params.locale || i18n.defaultLocale)

		await i18n.setLocale(this.currentLocale)
	}

	/**
	 * @param response
	 * @param i18n
	 */
	@VuexAction
	async updatei18nFromResponse(
		{ response, i18n }: {
			response: SearchPageResults,
			i18n: NuxtI18nInstance
		}
	): Promise<void> {
		let selectedLocale = get(response, 'meta.locale', i18n.defaultLocale)
		if (Array.isArray(selectedLocale)) {
			selectedLocale = first(selectedLocale)
		}
		await i18n.setLocale(selectedLocale)
	}

	@VuexMutation
	setCurrentLocale(locale: Locale): void {
		this.currentLocale = locale
	}

}
