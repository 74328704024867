import { render, staticRenderFns } from "./SearchPageHeaderNavigation.vue?vue&type=template&id=0926226c&scoped=true&"
import script from "./SearchPageHeaderNavigation.vue?vue&type=script&lang=ts&"
export * from "./SearchPageHeaderNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./SearchPageHeaderNavigation.vue?vue&type=style&index=0&id=0926226c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0926226c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginOrRegisterButton: require('/home/node/client/components/LoginOrRegisterButton.vue').default})
