








































import LazyHydrate from 'vue-lazy-hydration'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { Component, Vue, getModule } from 'nuxt-property-decorator'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import ActiveFiltersModule from '@client/store/Module/ActiveFiltersModule'
import ProductFeatureModule from '@client/store/Module/ProductFeatureModule'
import { ProductFeature } from '@client/store/Domain/Feature/ProductFeature'
import { ProductFeatureValue } from '@client/store/Domain/Feature/ProductFeatureValue'

@Component({
	name: 'FeaturesFilter',
	components: { FedraLink, LazyHydrate }
})
export default class FeaturesFilter extends Vue {

	productFeatureMD: ProductFeatureModule
	activeFiltersMD: ActiveFiltersModule

	get markerIcon(): faCheck {
		return faCheck
	}

	getListItemClass(productFeatureValue: ProductFeatureValue): string {
		const itemClassBase = 'cp-filters-features_filter-itm-itm'
		if (productFeatureValue.totalEntries <= 0)
			return `${itemClassBase} disabled`

		return itemClassBase
	}

	get getAllFeatureGroups(): ProductFeature[] {
		return this.productFeatureMD.getFeatureGroups
	}

	get getAvailableFeatureGroups(): ProductFeature[] {
		return this.productFeatureMD.getAvailableFeatureGroups
	}

	get getSelected(): number[] {
		return this.activeFiltersMD.getSelectedFeatureIds
	}

	get getCollectionChecked(): ProductFeature[] {
		return this.productFeatureMD.getCollectionChecked(this.getSelected)
	}

	getSelectedClass(brandID: number): 'selected'|'' {
		return this.getSelected.includes(brandID) ? 'selected' : ''
	}

	created(): void {
		this.productFeatureMD = getModule(ProductFeatureModule, this.$store)
		this.activeFiltersMD = getModule(ActiveFiltersModule, this.$store)
	}
}
