import { map } from 'lodash'
import { RouterOptions } from 'vue-router'
import AboutPage from '@client/pages/AboutPage.vue'
import SearchPage from '@client/pages/SearchPage.vue'
import { RouteConfig } from 'vue-router/types/router'
import MerchantXMLPage from '@client/pages/Merchant/MerchantXMLPage.vue'
import MerchantLoginPage from '@client/pages/Merchant/MerchantLoginPage.vue'
import MerchantRegisterPage from '@client/pages/Merchant/MerchantRegisterPage.vue'
import MerchantForgotPasswordPage from '@client/pages/Merchant/MerchantForgotPasswordPage.vue'

function createRoute(routeName: string, routeOptions: RouteConfig): RouteConfig {
	return {
		...routeOptions,
		meta: {
			routeId: `${ routeName }`
		}
	}
}

export default <RouterOptions> {
	mode: 'history',
	base: '/',
	routes: [
		{
			path: '/:locale?/search/',
			component: SearchPage
		},
		{
			path: '/:locale?/c/:category_id/:category_slug?/',
			component: SearchPage
		},
		{
			path: '/:locale?/c/:category_id/:category_slug?/m/:brand_ids/:brand_slugs?',
			component: SearchPage
		},
		{
			path: '/:locale?/c/:category_id/:category_slug?/f/:feature_ids/:feature_slugs?',
			component: SearchPage
		},
		{
			path: '/:locale?/c/:category_id/:category_slug?/m/:brand_ids/:brand_slugs?/f/:feature_ids/:feature_slugs?',
			component: SearchPage
		},
		{
			path: '/:locale?/m/:brand_ids/:brand_slugs?',
			component: SearchPage
		},
		{
			path: '/:locale?/m/:brand_ids/:brand_slugs?/f/:feature_ids/:feature_slugs?',
			component: SearchPage
		},
		createRoute('MerchantRegisterPage', {
			path: '/:locale?/merchant/merchant_register(\\.htm|\\.php)?',
			component: MerchantRegisterPage,
		}),
		{
			path: '/:locale?/merchant/merchant_login(\\.htm|\\.php)?',
			component: MerchantLoginPage
		},
		{
			path: '/:locale?/merchant/merchant_forgot_pwd_form(\\.htm|\\.php)?',
			component: MerchantForgotPasswordPage
		},
		{
			path: '/:locale?/merchant/merchant_xml(\\.htm|\\.php)?',
			component: MerchantXMLPage
		},
		{
			path: '/:locale?/about/*',
			component: AboutPage
		},
		{
			path: '/:locale?/about',
			component: AboutPage
		},
		{
			path: '/:locale?/*',
			component: AboutPage
		}
	]
}
