













import { Component, Vue } from 'nuxt-property-decorator'
import MerchantFeedXMLSection from '@client/components/Merchant/MerchantFeedXMLSection.vue'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faRobot } from '@fortawesome/free-solid-svg-icons'

@Component({
	name: 'MerchantFeedXMLSectionFedraBot',
	components: { MerchantFeedXMLSection },
})
export default class MerchantFeedXMLSectionFedraBot extends Vue {

	botIcon: IconDefinition = faRobot

}

