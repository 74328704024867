import { Vue } from 'nuxt-property-decorator'

export default (): void => {
	Vue.filter(
		'currency',
		function (value) {
			return `${value}€`
		}
	)
}
