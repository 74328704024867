














import { ApexOptions } from 'apexcharts'
import PriceRangeModule from '@client/store/Module/PriceRangeModule'
import { Component, getModule, Vue } from 'nuxt-property-decorator'

@Component({
	name: 'MinPriceHistogram',
	components: {
		VueApexCharts: () => {
			return process.client
				? import('vue-apexcharts')
				: Promise.resolve({ render: (h: any) => h('div') })
		}
	}
})
export default class MinPriceHistogram extends Vue {

	isMounted = false

	chartHeight = 100

	priceRangeMD: PriceRangeModule

	mounted(): void {
		this.isMounted = true
		this.priceRangeMD = getModule(PriceRangeModule, this.$store)
	}

	get chartSeries(): Array<{ 'data': number[] }> {
		return [{
			data: this.priceRangeMD.getMinPriceHistogramSeries
		}]
	}

	chartOptions = <ApexOptions> {
		chart: {
			type: 'bar',
			width: '100%',
			zoom: false,
			offsetX: -12.5,
			offsetY: 0,
			height: this.chartHeight,
			toolbar: {
				show: false
			}
		},
		grid: {
			show: false
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '80%',
				endingShape: 'rounded'
			},
		},
		dataLabels: {
			enabled: false
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			enabled: false
		},
		legend: {
			show: false
		},
		xaxis: {
			offsetX: 0,
			offsetY: 0,
			labels: {
				show: false
			}
		},
		yaxis: [{
			labels: {
				show: false
			}
		}],
		theme: {
			monochrome: {
				enabled: true,
				color: '#BF0882',
				shadeTo: 'light',
				shadeIntensity: 0.35
			}
		}
	}

}
