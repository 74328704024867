import { get, join } from 'lodash'
import Collectable from '@client/store/Common/Collectable'
import Product from '@client/store/Domain/Product/Product'
import { ProductDM } from '@client/store/Domain/Product/ProductDataMapper'
import SearchPageResults from '@microservice/Nuxtgen/API/DTO/SearchPageResults'
import { Module, VuexMutation, VuexModule, VuexAction } from 'nuxt-property-decorator'

@Module({
	name: 'Module/ProductModule',
	namespaced: true,
	stateFactory: true
})
export default class ProductModule extends VuexModule {
	collection = new Collectable<Product>()
	total = 0
	storeIdCardinality = 0

	get getStoreIdCardinality(): number {
		return this.storeIdCardinality
	}

	get getProductUrl(): (product: Product) => string {
		return (product: Product) => {
			return '/' + join([
				get(product, 'category_slug'),
				get(product, 'slug'),
				`m${get(product, 'product_id')}`
			], '/')
		}
	}

	get getCollection(): Collectable<Product> {
		return this.collection
	}

	get getTotalCount(): number {
		return this.total
	}

	@VuexAction({ rawError: true })
	async updateFromResponse(response: SearchPageResults): Promise<void> {
		const productsDM = new ProductDM().responseDataToCollection(response.products)
		this.setCollection(productsDM)
		this.setTotalCount(response.total_count)
		this.setStoreIdCardinality(response.store_id_cardinality)
	}

	@VuexMutation
	setStoreIdCardinality(storeIdCardinality: number): void {
		this.storeIdCardinality = storeIdCardinality
	}

	@VuexMutation
	setCollection(collection: Product[]): void {
		this.collection = new Collectable<Product>(collection)
	}

	@VuexMutation
	setTotalCount(value: number): void {
		this.total = value
	}
}
