































import { Component, Emit, Prop, PropSync, Vue } from 'nuxt-property-decorator'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { uniqueId } from 'lodash'

@Component({
	name: 'GenericModal'
})
export default class GenericModal extends Vue {

	isModalCurrentlyOpen: boolean = false

	@Prop({ required: false, default: false })
	shouldModalStartInOpenState!: boolean

	@Prop({ type: String, required: true })
	uniqueId!: string

	@Prop({ type: String, default: '#9A9A9B' })
	closeBtnColor!: string

	get getMyId(): string {
		return this.uniqueId
	}

	get getExitModalIcon(): typeof faTimes {
		return faTimes
	}

	@Emit('modal-opened')
	openModal() {
		this.isModalCurrentlyOpen = true
	}

	@Emit('modal-closed')
	closeModal() {
		this.isModalCurrentlyOpen = false
	}

	mounted() {
		this.isModalCurrentlyOpen = this.shouldModalStartInOpenState
	}

	created() {
		this.$on('modal-open', this.openModal)
		this.$on('modal-close', this.closeModal)
	}

}
