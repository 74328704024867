import SearchPageResults from '@microservice/Nuxtgen/API/DTO/SearchPageResults'
import { Module, VuexMutation, VuexModule, VuexAction } from 'nuxt-property-decorator'

export const defaultPageSize = 36

@Module({
	name: 'Module/ExternalSourcesModule',
	namespaced: true,
	stateFactory: true
})
export default class ExternalSourcesModule extends VuexModule {
	instanceRoot: string
	productImageThumbnailSource: string
	isLive: boolean

	get getGlobalRoot(): string {
		return 'https://fedra.com/'
	}

	get getMemberWelcomeUrl(): string {
		return `${this.instanceRoot}/member_welcome.php`
	}

	get getInstanceRoot(): string {
		return this.instanceRoot
	}

	get mediaStreamLocation(): string {
		return (this.isLive)
			? `${this.instanceRoot}/mediastream`
			: `http://localhost:7200/media_stream-image`
	}

	get getProductImageThumbnailSource(): string {
		return `${this.getInstanceRoot}/${this.productImageThumbnailSource}`
	}

	get getIsLive(): boolean {
		return this.isLive
	}

	@VuexAction
	async updateFromStatic(locale: string): Promise<void> {
		this.setInstanceRoot(`https://${locale}.fedra.com`)
		this.setIsLive(true)
	}

	@VuexAction
	async updateFromResponse(response: SearchPageResults): Promise<void> {
		this.setInstanceRoot(response.meta.instanceRoot)
		this.setProductImageThumbnailSource(response.meta.productImageThumbnailSource)
		this.setIsLive(response.meta.isLive)
	}

	@VuexMutation
	public setInstanceRoot(instanceRoot: string): void {
		this.instanceRoot = instanceRoot
	}

	@VuexMutation
	public setProductImageThumbnailSource(productImageThumbnailSource: string): void {
		this.productImageThumbnailSource = productImageThumbnailSource
	}

	@VuexMutation
	public setIsLive(isLive: boolean): void {
		this.isLive = isLive
	}
}
