












import { lowerCase, upperFirst } from 'lodash'
import Category from '@client/store/Domain/Category/Category'
import ProductModule from '@client/store/Module/ProductModule'
import PageMetaModule from '@client/store/Module/PageMetaModule'
import CategoryModule from '@client/store/Module/CategoryModule'
import PaginationModule from '@client/store/Module/PaginationModule'
import PriceRangeModule from '@client/store/Module/PriceRangeModule'
import { Component, getModule, Prop, Vue } from 'nuxt-property-decorator'
import ActiveFiltersModule from '@client/store/Module/ActiveFiltersModule'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'
import CategoryDisplayOption from '@microservice/Category/API/Enum/CategoryDisplayOption'

@Component({
	name: 'CategoryHeader'
})
export default class CategoryHeader extends Vue {

	@Prop({ default: false, type: Boolean }) useCategoryParentTitleInsteadOfPageMeta = false

	productMD: ProductModule
	categoryMD: CategoryModule
	pageMetaMD: PageMetaModule
	paginationMD: PaginationModule
	priceRangeMD: PriceRangeModule
	activeFiltersMD: ActiveFiltersModule
	externalSourcesMD: ExternalSourcesModule

	get getPageNum(): number {
		return this.paginationMD.getPage
	}

	get getPageTitle(): string {
		if (this.getPageNum < 2) return ''
		return `${this.$t('constants_commons.page')} ${this.getPageNum}`
	}

	get shouldCategoryDescriptionBeDisplayed(): boolean {
		if (this.activeFiltersMD.getSelectedBrandIds.length > 0)
			return false

		if (this.activeFiltersMD.getSelectedFeatureIds.length > 0)
			return false

		if (!(null === this.priceRangeMD.getMinPriceFrom && null === this.priceRangeMD.getMinPriceTo))
			return false

		return 1 === this.getPageNum
	}

	get getCategoryDescription(): string {
		return this.getCategory.detail
	}

	get getPageMetaTitle(): string {
		return this.pageMetaMD.getPageMeta.titleClean
	}

	get getCategory(): Category {
		return this.categoryMD.collection.parent
	}

	get getInstanceUrl(): string {
		return this.externalSourcesMD.getInstanceRoot
	}

	get supportsBannerDisplay(): boolean {
		return !this.categoryMD.doesParentSupportDisplayOption(CategoryDisplayOption.BANNER)
			&& !this.categoryMD.isCategoryParentRoot
	}

	get getCategoryHeaderMain(): string {
		return this.getPageMetaTitle
	}

	get getCategoryHeaderLabel(): string {
		return upperFirst(lowerCase([
			this.$t('constants_commons.see'),
			this.getCategory.count || this.productMD.total,
			this.$t('constants.products'),
			this.$t('constants_commons.from'),
			this.productMD.getStoreIdCardinality,
			this.$t('listing_product_card.listing_product_card_stores')
		].join(' ')))
	}

	created () {
		this.productMD = getModule(ProductModule, this.$store)
		this.categoryMD = getModule(CategoryModule, this.$store)
		this.pageMetaMD = getModule(PageMetaModule, this.$store)
		this.paginationMD = getModule(PaginationModule, this.$store)
		this.priceRangeMD = getModule(PriceRangeModule, this.$store)
		this.activeFiltersMD = getModule(ActiveFiltersModule, this.$store)
		this.externalSourcesMD = getModule(ExternalSourcesModule, this.$store)
	}

}
