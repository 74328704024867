import Taxonomy from '@microservice/Nuxtgen/API/Enum/Taxonomy'
import ResponseMeta from '@microservice/Nuxtgen/API/DTO/ResponseMeta'
import ElasticBrandModel from '@microservice/Brand/Model/ElasticBrandModel'
import FedraProductModel from '@microservice/Product/Model/FedraProductModel'
import { ProductFeatureValueKP } from '@microservice/Product/API/Type/KeyProperties'
import { FilterResponse } from '@microservice/Nuxtgen/API/DTO/FilterResponse'
import ProductSearchResults from '@microservice/Product/API/DTO/ProductSearchResults'
import { CategoriesTree, CategoryBreadCrumbs } from '@microservice/Category/Type/CategoriesTreeType'
import { ProductFeatureListFilterDTO } from '@microservice/Product/API/DTO/ProductFeatureListFilterDTO'
import { ProductFeatureValueFilterDTO } from '@microservice/Product/API/DTO/ProductFeatureValueFilterDTO'
import PriceHistogramSamplePointDTO from '@microservice/Nuxtgen/API/DTO/PriceHistogramSamplePointDTO'

export default class SearchPageResults {
	products: FedraProductModel[]
	products_count: number
	store_id_cardinality: number
	total_count: number
	breadcrumbs: CategoryBreadCrumbs[]
	categories: CategoriesTree
	rootCategories?: CategoriesTree
	brands?: ElasticBrandModel[]
	activeProductFeatureValues?: Record<ProductFeatureValueKP, ProductFeatureValueFilterDTO>
	productFeatures?: ProductFeatureListFilterDTO
	minPriceHistogram?: Array<PriceHistogramSamplePointDTO>
	filters: FilterResponse
	taxonomy: Taxonomy
	meta?: ResponseMeta

	constructor(
		{
			products,
			categories,
			rootCategories,
			breadcrumbs,
			brands,
			activeProductFeatureValues,
			productFeatures,
			minPriceHistogram,
			filters,
			taxonomy,
			meta
		} : {
			products: ProductSearchResults,
			categories: CategoriesTree,
			rootCategories?: CategoriesTree,
			breadcrumbs?: CategoryBreadCrumbs[],
			brands?: ElasticBrandModel[],
			activeProductFeatureValues?: Record<ProductFeatureValueKP, ProductFeatureValueFilterDTO>,
			productFeatures?: ProductFeatureListFilterDTO,
			minPriceHistogram?: Array<PriceHistogramSamplePointDTO>,
			filters?: FilterResponse,
			taxonomy?: Taxonomy,
			meta?: ResponseMeta
		}
	) {
		this.products = products.collection
		this.products_count = products.count
		this.store_id_cardinality = products.store_id_cardinality
		this.total_count = products.total
		this.categories = categories
		this.rootCategories = rootCategories
		this.breadcrumbs = breadcrumbs
		this.brands = brands
		this.activeProductFeatureValues = activeProductFeatureValues
		this.productFeatures = productFeatures
		this.minPriceHistogram = minPriceHistogram
		this.filters = filters
		this.taxonomy = taxonomy
		this.meta = meta
	}
}
