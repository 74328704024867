
























import SearchModule from '@client/store/Module/SearchModule'
import { Component, getModule, Vue } from 'nuxt-property-decorator'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'

@Component({
	name: 'SearchBar'
})
export default class SearchBar extends Vue {

	searchMD: SearchModule

	get getSearchIcon(): faSearch {
		return faSearch
	}

	created() {
		this.searchMD = getModule(SearchModule, this.$store)
	}

}
