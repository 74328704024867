import { render, staticRenderFns } from "./CategoryBanner.vue?vue&type=template&id=560580e5&scoped=true&"
import script from "./CategoryBanner.vue?vue&type=script&lang=ts&"
export * from "./CategoryBanner.vue?vue&type=script&lang=ts&"
import style0 from "./CategoryBanner.vue?vue&type=style&index=0&id=560580e5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "560580e5",
  null
  
)

export default component.exports