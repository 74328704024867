



















































































































import { map } from 'lodash'
import { getModule } from 'vuex-module-decorators'
import { Component, Vue } from 'nuxt-property-decorator'
import SocialModule from '@client/store/Module/SocialModule'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { faSlash } from '@fortawesome/free-solid-svg-icons/faSlash'
import SocialMeta from '@client/store/Domain/Support/Social/SocialMeta'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import FedraInstanceMetaModule from '@client/store/Module/FedraInstanceMetaModule'
import SocialMetaUsageLocation from '@client/store/Domain/Support/Social/SocialMetaUsageLocation'

@Component({
	name: 'AboutJoinUs',
	components: { FedraLink }
})
export default class AboutJoinUs extends Vue {

	socialMD: SocialModule
	fedraInstanceMetaMD: FedraInstanceMetaModule

	get envelopeIcon(): typeof faEnvelope {
		return faEnvelope;
	}

	get getCountrySeparatorIcon(): typeof faSlash {
		return faSlash
	}

	get getMerchantSocialSeparatorIcon(): typeof faSlash {
		return faSlash
	}

	get getInvestorSocialSeparatorIcon(): typeof faSlash {
		return faSlash
	}

	get getJoinUsCountries(): Array<{ link: string, name: string }> {
		return map(this.fedraInstanceMetaMD.getActiveInstances, (instance) => {
			return {
				name: instance.name,
				link: `${instance.url}/merchant/merchant_register.php`
			}
		})
	}

	get getInvestorSocialMetas(): Array<SocialMeta> {
		return this.socialMD.getFilteredSocialMetasByUsageLocation(SocialMetaUsageLocation.AboutJoinUsInvestor)
	}

	get getMerchantSocialMetas(): Array<SocialMeta> {
		return this.socialMD.getFilteredSocialMetasByUsageLocation(SocialMetaUsageLocation.AboutJoinUsMerchant)
	}

	created() {
		this.socialMD = getModule(SocialModule, this.$store)
		this.fedraInstanceMetaMD = getModule(FedraInstanceMetaModule, this.$store)
	}
}

