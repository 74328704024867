


















































import VueHorizontal from 'vue-horizontal'
import { Component, Vue } from 'nuxt-property-decorator'
import GenericModal from '@client/components/Utilities/GenericModal.vue'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons/faLongArrowAltLeft'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight'

@Component({
	name: 'AboutWeAreFedra',
	components: { VueHorizontal, GenericModal }
})
export default class AboutWeAreFedra extends Vue {

	isWeAreFedraVideoVisible: boolean = false
	hasPrev: boolean = false
	hasNext: boolean = true

	$refs!: {
		weAreFedraVideoModal: GenericModal
		horizontal: HTMLElement,
		weAreFedraVideo: HTMLVideoElement
	}

	get leftArrowIcon(): typeof faLongArrowAltLeft {
		return faLongArrowAltLeft
	}

	get rightArrowIcon(): typeof faLongArrowAltRight {
		return faLongArrowAltRight
	}

	public pauseWeAreFedraVideo(): void {
		this.$refs.weAreFedraVideo.pause()
	}

	public prev(): void {
		this.$refs.horizontal.prev()
	}

	public next(): void {
		this.$refs.horizontal.next()
	}

	onScroll({hasPrev, hasNext}) {
		this.hasPrev = hasPrev
		this.hasNext = hasNext
	}

}

