import { filter } from 'lodash'
import SocialMeta from '@client/store/Domain/Support/Social/SocialMeta'
import { Module, VuexModule } from 'nuxt-property-decorator'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import SocialMetaUsageLocation from '@client/store/Domain/Support/Social/SocialMetaUsageLocation'

@Module({
	name: 'Module/SocialModule',
	namespaced: true,
	stateFactory: true
})
export default class SocialModule extends VuexModule {

	socialMetas: Array<SocialMeta> = [
		{
			name: 'Facebook',
			url: 'https://www.facebook.com/fedra.comGreece/',
			icon: faFacebook,
			active: true,
			usages: [
				SocialMetaUsageLocation.AboutHeader,
				SocialMetaUsageLocation.AboutJoinUsMerchant,
			]
		},
		{
			name: 'Instagram',
			url: 'https://www.instagram.com/fedracom/',
			icon: faInstagram,
			active: true,
			usages: [
				SocialMetaUsageLocation.AboutHeader,
				SocialMetaUsageLocation.AboutJoinUsMerchant,
			]
		},
		{
			name: 'CrunchBase',
			url: 'https://www.crunchbase.com/organization/fedra',
			icon: '',
			active: true,
			usages: [
				SocialMetaUsageLocation.AboutJoinUsInvestor
			]
		},
		{
			name: 'LinkedIn',
			url: 'https://www.linkedin.com/company/fedracom/',
			icon: '',
			active: true,
			usages: [
				SocialMetaUsageLocation.AboutJoinUsInvestor,
				SocialMetaUsageLocation.AboutJoinUsMerchant,
			]
		}
	]

	get getActiveSocialMetas(): Array<SocialMeta> {
		return filter(this.socialMetas, (socialMeta) => socialMeta.active)
	}

	get getFilteredSocialMetasByUsageLocation(): (location: SocialMetaUsageLocation) => Array<SocialMeta> {
		return (location: SocialMetaUsageLocation) =>
			filter(this.getActiveSocialMetas, (socialMeta) => socialMeta.usages.includes(location))
	}

}
