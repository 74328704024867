














import Collectable from '@client/store/Common/Collectable'
import { Component, Vue, getModule } from 'nuxt-property-decorator'
import BrandsFilter from '@client/components/Filters/BrandsFilter.vue'
import FeaturesFilter from '@client/components/Filters/FeaturesFilter.vue'
import SortingOptions from '@client/components/Listing/SortingOptions.vue'
import ProductFeatureModule from '@client/store/Module/ProductFeatureModule'
import { ProductFeature } from '@client/store/Domain/Feature/ProductFeature'
import PriceRangeFilter from '@client/components/Filters/PriceRangeFilter.vue'
import CategoriesFilter from '@client/components/Filters/CategoriesFilter.vue'

@Component({
	components: { PriceRangeFilter, FeaturesFilter, BrandsFilter, CategoriesFilter, SortingOptions }
})
export default class SearchFilters extends Vue {
	productFeatureMD: ProductFeatureModule

	get collection(): Collectable<ProductFeature> {
		return this.productFeatureMD.collection
	}

	created(): void {
		this.productFeatureMD = getModule(ProductFeatureModule, this.$store)
	}
}
