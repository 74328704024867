









import { map } from 'lodash'
import { MetaInfo } from 'vue-meta/types/vue-meta'
import { Component, getModule, Vue } from 'nuxt-property-decorator'
import AboutHome from '@client/components/About/AboutHome.vue'
import AboutJoinUs from '@client/components/About/AboutJoinUs.vue'
import AboutWeAreFedra from '@client/components/About/AboutWeAreFedra.vue'
import AboutFedraWorld from '@client/components/About/AboutFedraWorld.vue'
import FedraInstanceMetaModule from '@client/store/Module/FedraInstanceMetaModule'
import FedraInstanceInfo from '../store/Domain/Support/FedraInstance/FedraInstanceInfo'

@Component({
	name: 'AboutPage',
	key: route => route.fullPath,
	watchQuery: [],
	components: {
		LazyAboutHome: AboutHome,
		LazyAboutWeAreFedra: AboutWeAreFedra,
		LazyAboutFedraWorld: AboutFedraWorld,
		LazyAboutJoinUs: AboutJoinUs
	},
	layout: 'about'
})
export default class AboutPage extends Vue {

	fedraInstanceMetaMD: FedraInstanceMetaModule

	head(): MetaInfo {
		return {
			title: 'Fedra - Online Pharmacies Aggregator',
			meta: [
				{
					name: 'description',
					hid: 'description',
					content: 'we are working to implement the next ERA of online pharmacy & online beauty stores users experience.'
				}
			],
			htmlAttrs: {
				lang: this.$i18n.locale,
			},
			link: this.getLinkHrefLangs
		}
	}

	get getLinkHrefLangs(): Array<{ rel: 'alternate', href: string, hreflang: string }> {
		this.fedraInstanceMetaMD
		return map(this.fedraInstanceMetaMD.getActiveInstances, (instance: FedraInstanceInfo) => {
			return {
				rel: 'alternate',
				href: instance.url,
				hreflang: instance.hreflang
			}
		})
	}

	created() {
		this.fedraInstanceMetaMD = getModule(FedraInstanceMetaModule, this.$store)
	}

}
