import slugify from 'slugify'
import { without } from 'lodash'
import Brand from '@client/store/Domain/Brand/Brand'
import Category from '@client/store/Domain/Category/Category'
import RequestType from '@microservice/Common/Search/Type/RequestType'
import ActiveFiltersModel from '@client/store/Model/ActiveFiltersModel'
import PathValueObject from '@client/store/Domain/Support/PathBuilder/PathValueObject'
import { ProductFeatureValue } from '@client/store/Domain/Feature/ProductFeatureValue'
import { VuexAction, Module, VuexMutation, VuexModule, Vue } from 'nuxt-property-decorator'
import PathSegmentValueObject from '@client/store/Domain/Support/PathBuilder/PathSegmentValueObject'
import QueryStringValueObject from '@client/store/Domain/Support/PathBuilder/QueryStringValueObject'

@Module({
	name: 'Module/PathBuilderModule',
	namespaced: true,
	stateFactory: true
})
export default class PathBuilderModule extends VuexModule {
	filters = new ActiveFiltersModel()
	path = new PathValueObject()

	get getPath(): PathValueObject {
		return this.path
	}

	get getBrandUrl(): (brand: Brand) => string {
		return (brand: Brand) => {
			let currentBrandIds = this.filters.brands.collect('brand_id')
			let currentBrandSlugs = this.filters.brands.collect('slug')
			if (currentBrandIds.includes(brand.brand_id)) {
				currentBrandIds = without(currentBrandIds, brand.brand_id)
				currentBrandSlugs = without(currentBrandSlugs, brand.slug)
			} else {
				currentBrandIds.push(brand.brand_id)
				currentBrandSlugs.push(brand.slug)
			}
			const segment = new PathSegmentValueObject({ prefix: 'm', slugs: currentBrandSlugs, IDs: currentBrandIds })

			const path = new PathValueObject({
				category: this.path.category,
				brand: segment,
				feature: this.path.feature,
				querystring: this.path.querystring
			})

			return path.build
		}
	}

	get getCategoryUrl(): (category: Category) => string {
		return (category: Category) => {
			const segment = new PathSegmentValueObject({
				prefix: 'c',
				slugs: [category.slug],
				IDs: [category.category_id]
			})

			const path = new PathValueObject({
				category: segment,
				brand: this.path.brand,
				feature: this.path.feature,
				querystring: this.path.querystring
			})

			return path.build
		}
	}

	get getFeatureValueUrl(): (feature: ProductFeatureValue) => string {
		return (feature: ProductFeatureValue) => {
			let valueIds = this.filters.features.collect('product_feature_value_id')
			let valueSlugs = this.filters.features.collect('value')
			if (valueIds.includes(feature.product_feature_value_id)) {
				valueIds = without(valueIds, feature.product_feature_value_id)
				valueSlugs = without(valueSlugs, feature.value)
			} else {
				valueIds.push(feature.product_feature_value_id)
				valueSlugs.push(feature.value)
			}
			const segment = new PathSegmentValueObject({
				prefix: 'f',
				slugs: valueSlugs.map(slug => slugify(slug)),
				IDs: valueIds
			})

			const path = new PathValueObject({
				category: this.path.category,
				brand: this.path.brand,
				feature: segment,
				querystring: this.path.querystring
			})

			return path.build
		}
	}

	@VuexAction
	async updateFromRequest(query: RequestType): Promise<void> {
		if ('keyphrase' in query) {
			this.setQueryStringByKey({ key: 'keyphrase', value: query.keyphrase })
		} else {
			this.clearQueryStringByKey('keyphrase')
		}
	}

	@VuexAction
	async buildBrandSegment(): Promise<void> {
		const IDs = this.filters.brands.collect('brand_id')
		const slugs = this.filters.brands.collect('slug')

		const path = new PathSegmentValueObject({ prefix: 'm', slugs, IDs })
		this.setBrandSegment(path)
	}

	@VuexAction
	async buildCategorySegment(): Promise<void> {
		const ID = this.filters.category.category_id
		const slug = this.filters.category.slug

		const path = new PathSegmentValueObject({ prefix: 'c', slugs: [slug], IDs: [ID] })
		this.setCategorySegment(path)
	}

	@VuexAction
	async buildFeatureSegment(): Promise<void> {
		const IDs = this.filters.features.collect('product_feature_value_id')
		const slugs = this.filters.features.collect('value')

		const path = new PathSegmentValueObject({ prefix: 'f', slugs: slugs.map(slug => slugify(slug)), IDs })
		this.setFeatureSegment(path)
	}

	@VuexMutation
	setFilters(filters: ActiveFiltersModel): void {
		Vue.set(this, 'filters', filters)
	}

	@VuexMutation
	setBrandSegment(segment: PathSegmentValueObject): void {
		Vue.set(this.path, 'brand', segment)
	}

	@VuexMutation
	setCategorySegment(segment: PathSegmentValueObject): void {
		Vue.set(this.path, 'category', segment)
	}

	@VuexMutation
	setFeatureSegment(segment: PathSegmentValueObject): void {
		Vue.set(this.path, 'feature', segment)
	}

	@VuexMutation
	setQueryStringByKey({ key, value } : { key: string, value: string }): void {
		Vue.set(this.path, 'querystring', new QueryStringValueObject(this.path.querystring).setQuery(key, value))
	}

	@VuexMutation
	clearQueryStringByKey(key: string): void {
		Vue.set(this.path, 'querystring', new QueryStringValueObject(this.path.querystring).clearQuery(key))
	}

}
