import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import SocialMetaUsageLocation from './SocialMetaUsageLocation'

export default class SocialMeta {
	name: string
	url: string
	icon: string | IconDefinition
	active: boolean
	usages: Array<SocialMetaUsageLocation>
}
