import { render, staticRenderFns } from "./CategoryListingSmall.vue?vue&type=template&id=d814ca8e&scoped=true&"
import script from "./CategoryListingSmall.vue?vue&type=script&lang=ts&"
export * from "./CategoryListingSmall.vue?vue&type=script&lang=ts&"
import style0 from "./CategoryListingSmall.vue?vue&type=style&index=0&id=d814ca8e&lang=scss&scoped=true&"
import style1 from "./CategoryListingSmall.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d814ca8e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CategoryListingItemSmall: require('/home/node/client/components/Category/CategoryListingItemSmall.vue').default})
