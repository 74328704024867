





























import Product from '@client/store/Domain/Product/Product'
import NoScript from '@client/components/Utilities/NoScript.vue'
import SearchFilters from '@client/components/SearchFilters.vue'
import CategoryModule from '@client/store/Module/CategoryModule'
import PageMetaModule from '@client/store/Module/PageMetaModule'
import { Component, Vue, getModule } from 'nuxt-property-decorator'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import ProductListing from '@client/components/Product/ProductListing.vue'
import ProductFiltersModule from '@client/store/Module/ProductFiltersModule'
import CategoryDisplayOption from '@microservice/Category/API/Enum/CategoryDisplayOption'
import ToggleButtonDisplayCSSStateEnum from "@client/store/Domain/Support/Filter/ToggleButtonDisplayCSSStateEnum";

@Component({
	components: { NoScript, SearchFilters, ProductListing }
})
export default class ProductListingWithFilters extends Vue {
	categoryMD: CategoryModule
	pageMetaMD: PageMetaModule
	productFiltersMD: ProductFiltersModule
	mobileFilterClass: ToggleButtonDisplayCSSStateEnum = ToggleButtonDisplayCSSStateEnum.EXTENDED

	get getMobileFilterClass(): ToggleButtonDisplayCSSStateEnum {
		if (this.getIsDisplayingFiltersSidebar)
			return ToggleButtonDisplayCSSStateEnum.RETRACTED

		return this.mobileFilterClass
	}

	get getProducts(): Product[] {
		return this.categoryMD.collection.items
	}

	get supportsProductListingDisplay(): boolean {
		return this.categoryMD.doesParentSupportDisplayOption(CategoryDisplayOption.PRODUCT_LISTING)
			|| this.pageMetaMD.getPageMeta.hasFilters
	}

	get getIsDisplayingFiltersSidebar(): boolean {
		return this.productFiltersMD.getIsDisplayingFiltersSidebar
	}

	updateIsDisplayingFiltersSidebar(isDisplayingFiltersSidebar: boolean): void {
		this.productFiltersMD.setIsDisplayingFiltersSidebar(isDisplayingFiltersSidebar)
	}

	get getFiltersIcon(): faFilter {
		return faFilter
	}

	get getFiltersCloseIcon(): faTimes {
		return faTimes
	}

	get getOverlayClass(): string {
		if (this.getIsDisplayingFiltersSidebar)
			return 'cp-product-product_listing_with_filters-overlay active'

		return 'cp-product-product_listing_with_filters-overlay'
	}

	toggleProductFilters(): void {
		this.productFiltersMD.toggleIsDisplayingFiltersSidebar()
	}

	handleScrollForMobileFilters(): void {
		if (process.client) {
			let timeout: NodeJS.Timeout = null
			let referenceHeightPoint: number = 0
			let newHeightPoint: number = 0
			window.addEventListener("scroll", () => {
				clearTimeout(timeout)
				timeout = setTimeout(() => {
					newHeightPoint = window.scrollY

					const retractionCutoff = referenceHeightPoint + 300
					const extensionCutoff = referenceHeightPoint - 300
					const hasExceededRetractionCutoff = (retractionCutoff - newHeightPoint) < 0
					const hasExceededExtensionCutoff = (extensionCutoff - newHeightPoint) > 0

					if (hasExceededRetractionCutoff) {
						this.mobileFilterClass = ToggleButtonDisplayCSSStateEnum.RETRACTED
					}

					if (hasExceededExtensionCutoff) {
						this.mobileFilterClass = ToggleButtonDisplayCSSStateEnum.EXTENDED
					}

					if (hasExceededRetractionCutoff || hasExceededExtensionCutoff) {
						referenceHeightPoint = newHeightPoint
					}
				}, 50)
			})
		}
	}

	created(): void {
		this.handleScrollForMobileFilters()
		this.categoryMD = getModule(CategoryModule, this.$store)
		this.pageMetaMD = getModule(PageMetaModule, this.$store)
		this.productFiltersMD = getModule(ProductFiltersModule, this.$store)
	}
}
