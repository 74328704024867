
















import VueHorizontal from 'vue-horizontal'
import { filter, first, sortBy } from 'lodash'
import Brand from '@client/store/Domain/Brand/Brand'
import Category from '@client/store/Domain/Category/Category'
import CategoryModule from '@client/store/Module/CategoryModule'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import PageMetaModule from '@client/store/Module/PageMetaModule'
import { Component, getModule, Vue } from 'nuxt-property-decorator'
import ActiveFiltersModule from '@client/store/Module/ActiveFiltersModule'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'
import CategoryListingItem from '@client/components/Category/CategoryListingItem.vue'
import CategoryDisplayOption from '@microservice/Category/API/Enum/CategoryDisplayOption'

@Component({
	name: 'CategoryListing',
	components: { CategoryListingItem, FedraLink, VueHorizontal }
})
export default class CategoryListing extends Vue {

	categoryMD: CategoryModule
	pageMetaMD: PageMetaModule
	activeFiltersMD: ActiveFiltersModule
	externalSourcesMD: ExternalSourcesModule

	get getActiveCategories(): Category[] {
		return sortBy(filter(
			this.getCategories,
			category => category.is_valid && !category.is_sensitive && category.count > 0
		), category => -category.count)
	}

	get getCategories(): Category[] {
		return this.categoryMD.collection.children
	}

	get getMediaStreamLocation(): string {
		return this.externalSourcesMD.mediaStreamLocation
	}

	get supportsCategoryListingDisplay(): boolean {
		return this.categoryMD.doesParentSupportDisplayOption(CategoryDisplayOption.CATEGORY_LISTING)
	}

	getCategoryName(category: Category): string {
		if (this.pageMetaMD.getPageMeta.isBrandCanonical) {
			const firstBrand: Brand = first(this.activeFiltersMD.filters.brands.items)
			if (firstBrand) {
				return `${category.title} ${firstBrand.title}`
			}
		}

		return category.title
	}

	created () {
		this.categoryMD = getModule(CategoryModule, this.$store)
		this.pageMetaMD = getModule(PageMetaModule, this.$store)
		this.activeFiltersMD = getModule(ActiveFiltersModule, this.$store)
		this.externalSourcesMD = getModule(ExternalSourcesModule, this.$store)
	}

}
