import { BaseEntity } from '@client/store/Common/BaseEntity'

export default class Nestable<T extends BaseEntity> {
	parent: T
	children: T[]

	constructor(parent?: T, children?: T[]) {
		this.parent = parent
		this.children = children
	}

	toJSON(): string {
		return JSON.stringify({
			parent: this.parent,
			children: this.children
		})
	}

}
