





























































































































































































































import { Component, Vue } from 'nuxt-property-decorator'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import GenericModal from '@client/components/Utilities/GenericModal.vue'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import MerchantRegisterUSPs from '@client/components/Merchant/MerchantRegisterUSPs.vue'
import MerchantRegistrationContract from '@client/components/Merchant/MerchantRegistrationContract.vue'

@Component({
	name: 'MerchantRegisterForm',
	components: {
		GenericModal,
		MerchantRegistrationContract,
		MerchantRegisterUSPs,
		FedraLink,
		ValidationObserver,
		ValidationProvider
	}
})
export default class MerchantRegisterForm extends Vue {

	$refs!: {
		merchantRegistrationContractModal: GenericModal
	}

	form__email = ''
	form__person_name = ''
	form__store_name = ''
	form__mobile_number = ''
	form__store_url = ''
	form__store_xml = ''
	form__task = 'Member'
	form__action = 'Add'
	form__passwordLess = '1'
	form__return = 'merchant_register.php?'
	form__g_recaptcha_response = ''

	captchaError: string = ''
	termsChecked: boolean = false
	termsError: string = ''

	xmlMarkUpIcon: IconDefinition = faCode

	validateCaptcha(token: string) {
		this.form__g_recaptcha_response = token
		this.captchaError = ''
	}

	invalidateCaptcha(errorMessage: string) {
		this.form__g_recaptcha_response = ''
		this.captchaError = errorMessage
	}

	beforeDestroy() {
		try {
			this.$recaptcha.destroy()
		} catch (e) {
			//
		}
	}

	acceptAgreement()
	{
		this.termsChecked = true
		this.validateTerms();
	}

	declineAgreement()
	{
		this.termsChecked = false
		this.validateTerms();
	}

	validateTerms(): boolean
	{
		if (!this.termsChecked) {
			this.termsError = this.$i18n.t('merchant_register.terms_required') as string
			return false
		}

		this.termsError = ''
		return true
	}

	async onSubmit() {
		if (!this.form__g_recaptcha_response)
			this.captchaError = this.$i18n.t('merchant_register.captcha_required') as string

		this.validateTerms();
	}
}
