























































import Nestable from '@client/store/Common/Nestable'
import Category from '@client/store/Domain/Category/Category'
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import CategoryModule from '@client/store/Module/CategoryModule'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { Component, getModule, Vue } from 'nuxt-property-decorator'
import SortingOptions from '@client/components/Listing/SortingOptions.vue'
import LoginOrRegisterButton from '@client/components/LoginOrRegisterButton'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'

@Component({
	name: 'SearchPageHeaderNavigation',
	components: { SortingOptions, FedraLink, LoginOrRegisterButton }
})
export default class SearchPageHeaderNavigation extends Vue {

	categoryMD: CategoryModule
	externalSourcesMD: ExternalSourcesModule
	homeIcon: IconDefinition = faHome
	registerIcon: IconDefinition = faPen
	signInIcon: IconDefinition = faUser
	rightIcon: IconDefinition = faChevronRight

	get getCollection(): Nestable<Category> {
		return this.categoryMD.getRootCollection
	}

	created(): void {
		this.categoryMD = getModule(CategoryModule, this.$store)
		this.externalSourcesMD = getModule(ExternalSourcesModule, this.$store)
	}

}
