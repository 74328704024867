import Taxonomy from '@microservice/Nuxtgen/API/Enum/Taxonomy'
import SearchPageResults from '@microservice/Nuxtgen/API/DTO/SearchPageResults'
import { Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator'

@Module({
	name: 'Module/TaxonomyModule',
	namespaced: true,
	stateFactory: true
})
export default class TaxonomyModule extends VuexModule {

	taxonomy: Taxonomy = null

	get getTaxonomy(): Taxonomy {
		return this.taxonomy
	}

	@VuexAction
	async updateFromResponse(response: SearchPageResults): Promise<void> {
		this.setTaxonomy(response.taxonomy)
	}

	@VuexMutation
	setTaxonomy(taxonomy: Taxonomy) {
		this.taxonomy = taxonomy
	}

}
