import { map } from 'lodash'
import { NuxtI18nInstance } from '@nuxtjs/i18n'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'
import SearchPageResults from '@microservice/Nuxtgen/API/DTO/SearchPageResults'
import ListItem from '@client/store/Domain/Support/RichResults/org.schema/ListItem'
import { Module, VuexMutation, VuexModule, VuexAction } from 'nuxt-property-decorator'
import BreadcrumbList from '@client/store/Domain/Support/RichResults/org.schema/BreadcrumbList'
import { BreadCrumbsItemObject, BreadCrumbsObject, CategoryBreadCrumbs } from '@client/store/Model/BreadCrumbsModel'


@Module({
	name: 'Module/BreadcrumbModule',
	namespaced: true,
	stateFactory: true
})
export default class BreadcrumbModule extends VuexModule {
	breadcrumbs = new BreadCrumbsObject()

	get getBreadcrumbs(): BreadCrumbsObject {
		return this.breadcrumbs
	}

	@VuexAction
	async getStructuredData(externalSourcesMD: ExternalSourcesModule): Promise<BreadcrumbList> {
		const parentItem: ListItem = new ListItem({
			'@type': 'ListItem',
			name: this.breadcrumbs.parent.text,
			item: `${externalSourcesMD.getInstanceRoot}${this.breadcrumbs.parent.link}`,
			position: 1
		})

		const childItems: ListItem[] = map(
			this.breadcrumbs.children,
			(item, idx) => new ListItem({
				'@type': 'ListItem',
				name: item.text,
				item: `${externalSourcesMD.getInstanceRoot}${item.link}`,
				position: idx + 2
			})
		)

		return new BreadcrumbList({
			'@context': 'https://schema.org',
			'@type': 'BreadcrumbList',
			itemListElement: [ parentItem, ...childItems ]
		})
	}

	@VuexAction
	async updateFromResponse({ response, i18n } : { response: SearchPageResults, i18n: NuxtI18nInstance }): Promise<void> {
		this.setParent({ link: '/', text: i18n.t('constants_commons.home') as string })
		const categoriesBreadCrumbs = buildBreadCrumbs(response.categories.parent.breadcrumbs)
		this.setChildren(categoriesBreadCrumbs)

		function buildBreadCrumbs(breadcrumbs: CategoryBreadCrumbs[]): BreadCrumbsItemObject[] {
			return breadcrumbs.map((breadcrumb) => {
				const link = `/c/${breadcrumb.id}/${breadcrumb.slug}/`
				return new BreadCrumbsItemObject(link, breadcrumb.text)
			})
		}
	}

	@VuexMutation
	setBreadcrumbs(breadcrumbs: BreadCrumbsObject): void {
		this.breadcrumbs = breadcrumbs
	}

	@VuexMutation
	setParent(breadcrumb: BreadCrumbsItemObject): void {
		this.breadcrumbs.parent = breadcrumb
	}

	@VuexMutation
	setChildren(breadcrumbs: BreadCrumbsItemObject[]): void {
		this.breadcrumbs.children = breadcrumbs
	}
}
