

























import VueHorizontal from 'vue-horizontal'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { Component } from 'nuxt-property-decorator'
import CategoryListing from '@client/components/Category/CategoryListing.vue'
import CategoryDisplayOption from '@microservice/Category/API/Enum/CategoryDisplayOption'
import CategoryListingItemSmall from '@client/components/Category/CategoryListingItemSmall.vue'

@Component({
	name: 'CategoryListingSmall',
	components: { CategoryListingItemSmall, FedraLink, VueHorizontal },
	extends: CategoryListing
})
export default class CategoryListingSmall extends CategoryListing {

	hasPrev: boolean = false
	hasNext: boolean = false
	scrollWidth: number = 0
	left: number = 0
	progress: number = 0
	index: number = 0

	get supportsCategoryListingDisplay(): boolean {
		return this.categoryMD.doesParentSupportDisplayOption(CategoryDisplayOption.CATEGORY_LISTING_SMALL)
	}

	public onScrollDebounce({hasNext, hasPrev, scrollWidth, width, left}): void {
		this.hasPrev = hasPrev
		this.hasNext = hasNext
		this.scrollWidth = scrollWidth
		this.left = left
		this.progress = left / scrollWidth
		this.index = Math.round(left / width)
	}

}
