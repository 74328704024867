


































import LazyHydrate from 'vue-lazy-hydration'
import Nestable from '@client/store/Common/Nestable'
import Category from '@client/store/Domain/Category/Category'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import CategoryModule from '@client/store/Module/CategoryModule'
import TaxonomyModule from '@client/store/Module/TaxonomyModule'
import { Component, Vue, getModule, Prop } from 'nuxt-property-decorator'
import Taxonomy from '../../../server/Service/Nuxtgen/API/Enum/Taxonomy'

@Component({
	name: 'CategoriesFilter',
	components: { FedraLink, LazyHydrate }
})
export default class CategoriesFilter extends Vue {
	categoryMD: CategoryModule
	taxonomyMD: TaxonomyModule

	@Prop({ default: false }) isNestedDisplayEnabled: boolean

	get getCollection(): Nestable<Category> {
		return this.categoryMD.getCollection
	}

	get getCategoryLink(): (category: Category) => string {
		return (category: Category) => {
			if (Taxonomy.SEARCH === this.taxonomyMD.getTaxonomy)
				return `/c/${category.category_id}/${category.slug}`

			return category.link
		}
	}

	created(): void {
		this.categoryMD = getModule(CategoryModule, this.$store)
		this.taxonomyMD = getModule(TaxonomyModule, this.$store)
	}
}
