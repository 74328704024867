



























import LazyHydrate from 'vue-lazy-hydration'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { Component, Vue, getModule } from 'nuxt-property-decorator'
import BreadcrumbModule from '@client/store/Module/BreadcrumbModule'
import { BreadCrumbsObject } from '@client/store/Model/BreadCrumbsModel'

@Component({
	name: 'Breadcrumbs',
	components: { FedraLink, LazyHydrate }
})
export default class Breadcrumbs extends Vue {

	breadcrumbMD: BreadcrumbModule

	get getBreadcrumbs(): BreadCrumbsObject {
		return this.breadcrumbMD.getBreadcrumbs
	}

	isLast(position: number): boolean {
		return (this.getBreadcrumbs.children.length === (position + 1))
	}

	hasChildren(): boolean {
		return (this.getBreadcrumbs.children.length === 0)
	}

	created(): void {
		this.breadcrumbMD = getModule(BreadcrumbModule, this.$store)
	}

}
