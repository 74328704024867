






























import { faPen } from '@fortawesome/free-solid-svg-icons/faPen'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { Component, getModule, Vue } from 'nuxt-property-decorator'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'

@Component({
	name: 'LoginOrRegisterButton',
	components: { FedraLink }
})
export default class LoginOrRegisterButton extends Vue {

	externalSourcesMD: ExternalSourcesModule
	registerIcon: IconDefinition = faPen
	signInIcon: IconDefinition = faUser

	created(): void {
		this.externalSourcesMD = getModule(ExternalSourcesModule, this.$store)
	}

}
