









import { Component, Vue } from 'nuxt-property-decorator'
import PageMeta from '@microservice/Nuxtgen/API/DTO/PageMeta'
import MerchantBasePage from '@client/pages/Merchant/MerchantBasePage.vue'
import MerchantRegisterUSPs from '@client/components/Merchant/MerchantRegisterUSPs.vue'
import MerchantRegisterForm from '@client/components/Merchant/MerchantRegisterForm.vue'
import MerchantRegistrationContract from '@client/components/Merchant/MerchantRegistrationContract.vue'
import MerchantHeaderNavigation from '@client/components/Merchant/MerchantHeaderNavigation.vue'

@Component({
	name: 'MerchantRegisterPage',
	key: route => route.fullPath,
	watchQuery: [],
	extends: MerchantBasePage,
	nuxtI18n: false,
	components: {
		MerchantRegisterForm,
		MerchantRegistrationContract,
		MerchantRegisterUSPs,
	},
	layout: 'merchant'
})
export default class MerchantRegisterPage extends MerchantBasePage {

	meta: PageMeta = new PageMeta()

	head() {
		return {
			htmlAttrs: {
				lang: this.$i18n.locale,
			},
			...this.meta,
		}
	}

	mounted() {
		const navigationClass = Vue.extend(MerchantHeaderNavigation)
		this.$parent.$emit('updateAdditionalHeaderComponent', navigationClass)
	}

}
