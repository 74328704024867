import { render, staticRenderFns } from "./MerchantRegisterPage.vue?vue&type=template&id=7d0bd0a8&scoped=true&"
import script from "./MerchantRegisterPage.vue?vue&type=script&lang=ts&"
export * from "./MerchantRegisterPage.vue?vue&type=script&lang=ts&"
import style0 from "./MerchantRegisterPage.vue?vue&type=style&index=0&id=7d0bd0a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d0bd0a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MerchantRegisterForm: require('/home/node/client/components/Merchant/MerchantRegisterForm.vue').default,MerchantRegisterUSPs: require('/home/node/client/components/Merchant/MerchantRegisterUSPs.vue').default})
