import Brand from '@client/store/Domain/Brand/Brand'
import Collectable from '@client/store/Common/Collectable'
import Category from '@client/store/Domain/Category/Category'
import ActiveFiltersModel from '@client/store/Model/ActiveFiltersModel'
import { Module, VuexMutation, VuexModule } from 'nuxt-property-decorator'
import { ProductFeatureValue } from '@client/store/Domain/Feature/ProductFeatureValue'

@Module({
	name: 'Module/ActiveFiltersModule',
	namespaced: true,
	stateFactory: true
})
export default class ActiveFiltersModule extends VuexModule {
	filters = new ActiveFiltersModel()

	get getSelectedBrandIds(): Brand['brand_id'][] {
		return this.filters.brands.items.map((brand) => brand.brand_id)
	}

	get getSelectedFeatureIds(): ProductFeatureValue['product_feature_value_id'][] {
		return this.filters.features.items.map((feature) => feature.product_feature_value_id)
	}

	get getFilters(): ActiveFiltersModel {
		return this.filters
	}

	@VuexMutation
	setBrands(brands: Brand[]): void {
		this.filters.brands = new Collectable<Brand>(brands)
	}

	@VuexMutation
	setCategory(category: Category): void {
		this.filters.category = category
	}

	@VuexMutation
	setFeatures(features: ProductFeatureValue[]): void {
		this.filters.features = new Collectable<ProductFeatureValue>(features)
	}
}
