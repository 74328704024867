import Collectable from '@client/store/Common/Collectable'
import Taggable from '@client/store/Domain/Support/Filter/Taggable'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import ProductFeature from '@client/store/Domain/Feature/ProductFeature'
import ActiveFiltersModule from '@client/store/Module/ActiveFiltersModule'
import TagCloudItem from '@client/store/Domain/Support/Filter/TagCloudItem'
import SearchPageResults from '@microservice/Nuxtgen/API/DTO/SearchPageResults'
import { filter, keys, intersection, map, each, reduce, cloneDeep } from 'lodash'
import { ProductFeatureValueKP } from '@microservice/Product/API/Type/KeyProperties'
import { ProductFeatureValue } from '@client/store/Domain/Feature/ProductFeatureValue'
import { Module, VuexMutation, VuexModule, VuexAction } from 'nuxt-property-decorator'
import { ProductFeatureDM } from '@client/store/Domain/Feature/DataMapper/ProductFeatureDM'
import { ProductFeatureValueDM } from '@client/store/Domain/Feature/DataMapper/ProductFeatureValueDM'

@Module({
	name: 'Module/ProductFeatureModule',
	namespaced: true,
	stateFactory: true
})
export default class ProductFeatureModule extends VuexModule implements Taggable<ProductFeatureValueKP> {
	collection = new Collectable<ProductFeature>()

	get getSelectedTags(): (selectedIds: ProductFeatureValueKP[]) => TagCloudItem[] {
		return (selectedIds: ProductFeatureValueKP[]) => {
			return map(this.getCollectionChecked(selectedIds), (featureValue) => {
					return new TagCloudItem({
							name: `${ featureValue.feature }: ${ featureValue.value }`,
							url: featureValue.link
					})
			})
		}
	}

	get getFeatureGroups(): ProductFeature[] {
		return filter(this.getCollection.items, item => item.values.length > 0)
	}

	get getAvailableFeatureGroups(): ProductFeature[] {
		const groups = filter(this.getFeatureGroups, feature => {
			return feature.isValid
				&& feature.isDisplayedAsFilter
				&& reduce(feature.values, (acc, value) => acc += value.totalEntries, 0) > 0
		})

		return map(groups, group => {
			const newGroup = cloneDeep(group)
			newGroup.values = filter(group.values, value => value.totalEntriesClean > 0)
			return newGroup
		})
	}

	get getCollectionChecked(): (selectedFeatureValueIds: number[]) => ProductFeatureValue[] {
		return (selectedFeatureValueIds: number[]) => {
			const productFeatureValues: ProductFeatureValue[] = []
			for (const group of this.getFeatureGroups) {
				productFeatureValues.push(...group.values)
			}

			return filter(
				productFeatureValues,
				(value) =>
					selectedFeatureValueIds.includes(value.product_feature_value_id)
			)
		}
	}

	get getCollectionUnChecked(): (selectedFeatureValueIds: number[]) => ProductFeature[] {
		return (selectedFeatureValueIds: number[]) => {
			return filter(this.getFeatureGroups, feature => {
				return intersection(
					map(keys(feature), Number),
					selectedFeatureValueIds
				).length <= 0
			})
		}
	}

	get getCollection(): Collectable<ProductFeature> {
		return this.collection
	}

	@VuexAction({ rawError: true })
	async prepareUpdateFromResponse(
		{ response, activeFiltersMD, pathBuilderMD } : {
			response: SearchPageResults,
			activeFiltersMD: ActiveFiltersModule,
			pathBuilderMD: PathBuilderModule
		}
	): Promise<void> {
		let productFeatureFilterDM = []
		try {
			productFeatureFilterDM = new ProductFeatureValueDM().responseDataToCollection(response.activeProductFeatureValues)
		} catch (e) {
			// response product features are empty or invalid
		} finally {
			activeFiltersMD.setFeatures(productFeatureFilterDM)
			pathBuilderMD.setFilters(activeFiltersMD.getFilters)
		}
	}

	@VuexAction({ rawError: true })
	async updateFromResponse(
		{ response, pathBuilderMD } : {
			response: SearchPageResults,
			pathBuilderMD: PathBuilderModule
		}
	): Promise<void> {
		const featuresDM = new ProductFeatureDM().responseDataToCollection(response.productFeatures.items)
		const featuresWithLinks = featuresDM.map((feature) => {
			const values = feature.getValues
			each(values, value => value.setLink(pathBuilderMD.getFeatureValueUrl(value)))
			feature.setValues(values)
			return feature
		})
		this.setCollection(featuresWithLinks)
	}

	@VuexMutation
	setCollection(collection: ProductFeature[]): void {
		this.collection = new Collectable<ProductFeature>(collection)
	}
}
