export default class TagCloudItem {

	name: string

	url: string

	public constructor(data?: Partial<TagCloudItem>) {
		Object.assign(this, data)
	}

}
