

















import { Vue, Component, Prop, Emit } from 'nuxt-property-decorator'

@Component({
	name: 'AboutBurgerButton'
})
export default class AboutBurgerButton extends Vue {

	@Prop({ type: String, default: 'auto' })
	pointerEvents!: string

	@Prop({ type: String, default: 'white' })
	strokeColor!: string

	@Prop({ type: String, default: 'Main Menu' })
	title!: string

	@Prop({ type: String, default: 'Main Menu' })
	ariaLabel!: string

	@Prop({ type: String, default: '65' })
	burgerSVGHeight!: number

	@Prop({ type: String, default: '65' })
	burgerSVGWidth!: number

	isBurgerOpened: boolean = false

	$refs!: {
		mainToggleButton: HTMLElement
	}

	public toggleMenu(): void {
		if (this.isBurgerOpened)
			this.closeBurger()
		else
			this.openBurger()
	}

	@Emit('burger-opened')
	public openBurger(): void {
		if (this.$refs.mainToggleButton.classList.contains('opened'))
			return

		this.$refs.mainToggleButton.classList.add('opened')
		this.$refs.mainToggleButton.setAttribute(
			'aria-expanded',
			this.$refs.mainToggleButton.classList.contains('opened') as unknown as string
		)
		this.isBurgerOpened = true
	}

	@Emit('burger-closed')
	public closeBurger(): void {
		if (!this.$refs.mainToggleButton.classList.contains('opened'))
			return

		this.$refs.mainToggleButton.classList.remove('opened')
		this.$refs.mainToggleButton.setAttribute(
			'aria-expanded',
			this.$refs.mainToggleButton.classList.contains('opened') as unknown as string
		)
		this.isBurgerOpened = false
	}

}
