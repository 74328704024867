import { first, get } from 'lodash'
import { NuxtI18nInstance } from '@nuxtjs/i18n'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import BrandModule from '@client/store/Module/BrandModule'
import SearchModule from '@client/store/Module/SearchModule'
import SortingModule from '@client/store/Module/SortingModule'
import ProductModule from '@client/store/Module/ProductModule'
import PageMetaModule from '@client/store/Module/PageMetaModule'
import TaxonomyModule from '@client/store/Module/TaxonomyModule'
import CategoryModule from '@client/store/Module/CategoryModule'
import Locale from '@microservice/Common/Search/Type/LocaleType'
import PaginationModule from '@client/store/Module/PaginationModule'
import BreadcrumbModule from '@client/store/Module/BreadcrumbModule'
import PriceRangeModule from '@client/store/Module/PriceRangeModule'
import PathBuilderModule from '@client/store/Module/PathBuilderModule'
import RequestType from '@microservice/Common/Search/Type/RequestType'
import ActiveFiltersModule from '@client/store/Module/ActiveFiltersModule'
import ProductFeatureModule from '@client/store/Module/ProductFeatureModule'
import ExternalSourcesModule from '@client/store/Module/ExternalSourcesModule'
import SearchPageResults from '@microservice/Nuxtgen/API/DTO/SearchPageResults'
import GenericPageParams from '@client/store/Domain/Support/PathBuilder/GenericPageParams'
import FedraNuxtAppContext from '@client/store/Domain/Support/PageRefresh/FedraNuxtAppContext'
import AvailablePageParams from '@client/store/Domain/Support/PathBuilder/AvailablePageParams'
import { getModule, Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator'
import Process = NodeJS.Process


export class SearchPageContents {
	meta
}

@Module({
	name: 'Module/SearchPageRefreshModule',
	namespaced: true,
	stateFactory: true
})
export default class SearchPageRefreshModule extends VuexModule {

	currentLocale: Locale = null

	@VuexAction({ rawError: true })
	async refresh(appContext: FedraNuxtAppContext): Promise<SearchPageContents> {
		const sortingMD = getModule(SortingModule, appContext.store)
		const searchMD = getModule(SearchModule, appContext.store)
		const productMD = getModule(ProductModule, appContext.store)
		const paginationMD = getModule(PaginationModule, appContext.store)
		const priceRangeMD = getModule(PriceRangeModule, appContext.store)
		const breadcrumbMD = getModule(BreadcrumbModule, appContext.store)
		const brandMD = getModule(BrandModule, appContext.store)
		const pageMetaMD = getModule(PageMetaModule, appContext.store)
		const taxonomyMD = getModule(TaxonomyModule, appContext.store)
		const pathBuilderMD = getModule(PathBuilderModule, appContext.store)
		const activeFiltersMD = getModule(ActiveFiltersModule, appContext.store)
		const categoryMD = getModule(CategoryModule, appContext.store)
		const externalSourcesMD = getModule(ExternalSourcesModule, appContext.store)
		const productFeatureMD = getModule(ProductFeatureModule, appContext.store)

		const params = appContext.params
		const query = appContext.query

		const axios = appContext.$axios
		const i18n = appContext.i18n

		await Promise.all([
			paginationMD.updateFromRequest(query),
			priceRangeMD.updateFromRequest({ pathBuilderMD, query }),
			searchMD.updateFromRequest({ pathBuilderMD, query })
		])

		await this.updatei18nFromParamOrRequest({ params, query, i18n })

		const request = await this.buildRequest({
			i18n,
			paginationMD,
			priceRangeMD,
			params,
			query,
			sortingMD,
			pathBuilderMD
		})

		const response = await this.makePageRefreshRequest({ request, axios, process })
		// await this.updatei18nFromResponse({ response, i18n })
		pageMetaMD.setPageMeta(response.meta.pageMeta)

		await Promise.all([
			externalSourcesMD.updateFromResponse(response),
			breadcrumbMD.updateFromResponse({ response, i18n }),
			sortingMD.updateFromResponse(response.taxonomy),
			taxonomyMD.updateFromResponse(response)
		])

		const prepareUpdateParams = { response, activeFiltersMD, pathBuilderMD }
		await Promise.all([
			categoryMD.prepareUpdateFromResponse(prepareUpdateParams),
			brandMD.prepareUpdateFromResponse(prepareUpdateParams),
			productFeatureMD.prepareUpdateFromResponse(prepareUpdateParams)
		])

		await Promise.all([
			pathBuilderMD.buildBrandSegment(),
			pathBuilderMD.buildCategorySegment(),
			pathBuilderMD.buildFeatureSegment()
		])

		const updateParams = { response, pathBuilderMD }
		await Promise.all([
			categoryMD.updateFromResponse(updateParams),
			brandMD.updateFromResponse(updateParams),
			productFeatureMD.updateFromResponse(updateParams),
			productMD.updateFromResponse(response),
			pageMetaMD.updateFromResponse({
				i18n,
				categoryMD,
				breadcrumbMD,
				pageMeta: response.meta.pageMeta,
				pathBuilderMD,
				externalSourcesMD
			})
		])

		paginationMD.setTotalPageItems(productMD.getTotalCount)
		priceRangeMD.setMinPriceHistogram(response.minPriceHistogram)
		await priceRangeMD.updateFromResponse({ pathBuilderMD })

		return { meta: pageMetaMD.getPageMeta }
	}

	@VuexAction({ rawError: true })
	async buildRequest(
		{ i18n, paginationMD, priceRangeMD, params, query, sortingMD, pathBuilderMD }: {
			i18n: NuxtI18nInstance,
			paginationMD: PaginationModule,
			priceRangeMD: PriceRangeModule,
			params: AvailablePageParams,
			query: RequestType,
			sortingMD: SortingModule,
			pathBuilderMD: PathBuilderModule
		}
	): Promise<RequestType> {
		const request = <RequestType> {
			locales: [i18n.locale],
			offset: paginationMD.getItemsOffset,
			limit: paginationMD.getItemsPerPage,
			page: paginationMD.getPage
		}

		if (priceRangeMD.getMinPriceFrom) {
			request['min_price_from'] = priceRangeMD.getMinPriceFrom
		}

		if (priceRangeMD.getMinPriceTo) {
			request['min_price_to'] = priceRangeMD.getMinPriceTo
		}

		if ('keyphrase' in query) {
			request['search'] = query.keyphrase
		}

		if (params.category_id) {
			request['category_ids'] = [params.category_id]
		}

		if (params.brand_ids) {
			request['brand_ids'] = params.brand_ids
		}

		if (params.feature_ids) {
			request['product_feature_value_ids'] = params.feature_ids
		}

		request['sort'] = await sortingMD.updateFromRequest({ pathBuilderMD, query })

		return request
	}

	@VuexAction
	async updatei18nFromParamOrRequest(
		{ params, query, i18n }: {
			params: GenericPageParams,
			query: RequestType
			i18n: NuxtI18nInstance
		}
	): Promise<void> {
		if (!this.currentLocale)
			this.setCurrentLocale(params.locale || i18n.defaultLocale)

		await i18n.setLocale(this.currentLocale)
	}

	/**
	 * @param response
	 * @param i18n
	 */
	@VuexAction
	async updatei18nFromResponse(
		{ response, i18n }: {
			response: SearchPageResults,
			i18n: NuxtI18nInstance
		}
	): Promise<void> {
		let selectedLocale = get(response, 'meta.locale', i18n.defaultLocale)
		if (Array.isArray(selectedLocale)) {
			selectedLocale = first(selectedLocale)
		}
		await i18n.setLocale(selectedLocale)
	}

	@VuexAction({ rawError: true })
	async makePageRefreshRequest(
		{ request, axios, process }: {
			request: RequestType,
			axios: NuxtAxiosInstance,
			process: Process
		}
	): Promise<SearchPageResults> {
		const querystring = '?' + new URLSearchParams(request)

		try {
			return await axios.$get(`/search-page${querystring}`)
		} catch (responseError: unknown) {
			if (process.server) {
				console.error(responseError)
			}
			let errorMessage = get(responseError, 'response.data')
			if (!errorMessage || !(errorMessage instanceof String)) {
				errorMessage = 'Unable to retrieve data of response body'
			}
			throw new Error(errorMessage)
		}
	}

	@VuexMutation
	setCurrentLocale(locale: Locale): void {
		this.currentLocale = locale
	}

}
