import { render, staticRenderFns } from "./MerchantRegisterForm.vue?vue&type=template&id=1b19bcf6&scoped=true&"
import script from "./MerchantRegisterForm.vue?vue&type=script&lang=ts&"
export * from "./MerchantRegisterForm.vue?vue&type=script&lang=ts&"
import style0 from "./MerchantRegisterForm.vue?vue&type=style&index=0&id=1b19bcf6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b19bcf6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MerchantRegistrationContract: require('/home/node/client/components/Merchant/MerchantRegistrationContract.vue').default})
