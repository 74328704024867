import { pickBy } from 'lodash'
import { Module, VuexModule } from 'nuxt-property-decorator'
import Locale from '@microservice/Common/Search/Type/LocaleType'
import RegionMetaInfo from '@client/store/Domain/Support/FedraInstance/RegionMetaInfo'
import FedraInstanceInfo from '@client/store/Domain/Support/FedraInstance/FedraInstanceInfo'

@Module({
	name: 'Module/FedraInstanceMetaModule',
	namespaced: true,
	stateFactory: true
})
export default class FedraInstanceMetaModule extends VuexModule {

	regions: Record<string, RegionMetaInfo> = {
		gl: {
			name: 'Global',
			active: false
		},
		eu: {
			name: 'Europe',
			active: true
		},
		na: {
			name: 'North America',
			active: true
		}
	}

	instances: Record<Locale, FedraInstanceInfo> = {
		localization_landing_page: {
			name: 'Global',
			active: false,
			url: 'https://fedra.com',
			hreflang: 'x-default',
			flagImageUrl: require('~/assets/images/world.png'),
			region: 'gl'
		},
		el: {
			name: 'Greece',
			active: true,
			url: 'https://el.fedra.com',
			hreflang: 'el-gr',
			flagImageUrl: require('~/assets/images/gr.png'),
			region: 'eu',
			gtmId: 'GTM-PR6KNX9'
		},
		it: {
			name: 'Italy',
			active: true,
			url: 'https://it.fedra.com',
			hreflang: 'it-it',
			flagImageUrl: require('~/assets/images/it.png'),
			region: 'eu',
			gtmId: 'GTM-N8NDB9G'
		},
		fr: {
			name: 'France',
			active: true,
			url: 'https://fr.fedra.com',
			hreflang: 'fr-fr',
			flagImageUrl: require('~/assets/images/fr.png'),
			region: 'eu',
			gtmId: 'GTM-5HDQ93N'
		},
		de: {
			name: 'Germany',
			active: true,
			url: 'https://de.fedra.com',
			hreflang: 'de-de',
			flagImageUrl: require('~/assets/images/de.png'),
			region: 'eu',
			gtmId: 'GTM-MRDTRBJ'
		},
		es: {
			name: 'Spain',
			active: true,
			url: 'https://es.fedra.com',
			hreflang: 'es-es',
			flagImageUrl: require('~/assets/images/sp.png'),
			region: 'eu',
			gtmId: 'GTM-NXFJH3D'
		},
		se: {
			name: 'Sweden',
			active: false,
			url: 'https://se.fedra.com',
			hreflang: 'sv-se',
			flagImageUrl: require('~/assets/images/se.png'),
			region: 'eu'
		},
		uk: {
			name: 'United Kingdom',
			active: false,
			url: 'https://uk.fedra.com',
			hreflang: 'en-gb',
			flagImageUrl: require('~/assets/images/uk.png'),
			region: 'eu'
		},
		us: {
			name: 'United States of America',
			active: false,
			url: 'https://us.fedra.com',
			hreflang: 'en-us',
			flagImageUrl: require('~/assets/images/usa.png'),
			region: 'na'
		},
		mx: {
			name: 'Mexico',
			active: false,
			url: 'https://mx.fedra.com',
			hreflang: 'es-mx',
			flagImageUrl: require('~/assets/images/mx.png'),
			region: 'na'
		}
	}

	get getActiveRegions(): Record<string, RegionMetaInfo> {
		return pickBy(this.regions, (region) => region.active)
	}

	get getActiveInstances(): Record<Locale, FedraInstanceInfo> {
		return pickBy(this.instances, (instance) => instance.active)
	}

	get getInstancesByRegion(): (regionKey: string) => Record<Locale, FedraInstanceInfo> {
		return (regionKey: string) =>
			pickBy(this.instances, (instance) => regionKey === instance.region)
	}

}
