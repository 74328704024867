











import { Component, Vue } from 'nuxt-property-decorator'

@Component({
	name: 'MerchantFeedXMLSection',
})
export default class MerchantFeedXMLSection extends Vue {

}

