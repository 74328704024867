import { cloneDeep, join, map, merge, size } from 'lodash'

export default class QueryStringValueObject {
	private queryStringParts: Record<string, string> = {}

	constructor(data?: Partial<QueryStringValueObject>) {
		Object.assign(this, data)
	}

	get getQueryStringParts(): Record<string, string> {
		return this.queryStringParts
	}

	setQuery(key: string, value: string): QueryStringValueObject {
		const qs = cloneDeep(this)
		qs.queryStringParts[key] = value
		return qs
	}

	clearQuery(key: string): QueryStringValueObject {
		const qs = cloneDeep(this)
		delete qs.queryStringParts[key]
		return qs
	}

	clearAllQueries(): QueryStringValueObject {
		const qs = cloneDeep(this)
		qs.queryStringParts = {}
		return qs
	}

	mergeToQueryString(queryStringVO: QueryStringValueObject): QueryStringValueObject {
		const qsRecordsFrom = queryStringVO.getQueryStringParts
		let qsRecordsTo = cloneDeep(this.getQueryStringParts)
		qsRecordsTo = merge(qsRecordsTo, qsRecordsFrom)
		return new QueryStringValueObject({
			queryStringParts: qsRecordsTo
		} as Partial<QueryStringValueObject>)
	}

	get path(): string {
		if (size(this.queryStringParts) < 1)
			return ''

		return '?'+join(map(this.queryStringParts, (value, key) => {
			return `${key}=${value}`
		}), '&')
	}

	toJSON(): Record<string, string> {
		return this.queryStringParts
	}
}
