

















































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faCode, faRobot, faTable } from '@fortawesome/free-solid-svg-icons'
import MerchantLoginOrRegisterButton from '@client/components/Merchant/MerchantLoginOrRegisterButton'

@Component({
	name: 'MerchantFeedXMLHeaderBurgerNavigation',
	components: { FedraLink, MerchantLoginOrRegisterButton }
})
export default class MerchantFeedXMLHeaderBurgerNavigation extends Vue {

	navigationOpen: boolean = false

	codeIcon: IconDefinition = faCode
	botIcon: IconDefinition = faRobot
	tableIcon: IconDefinition = faTable

	@Prop({ default: true, type: Boolean }) isMobile!: boolean

	@Emit('navigation-opened')
	openNavigation() {
		this.navigationOpen = true
	}

	@Emit('navigation-closed')
	closeNavigation() {
		this.navigationOpen = false
	}

	public scrollIntoViewById(elementId: string): void {
		const element = document.getElementById(elementId)
		element.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		})
	}

	created() {
		this.$on('navigation-open', this.openNavigation)
		this.$on('navigation-close', this.closeNavigation)
		this.$on('header-burger-open', this.openNavigation)
		this.$on('header-burger-close', this.closeNavigation)
	}

}
