import Brand from '@client/store/Domain/Brand/Brand'
import Collectable from '@client/store/Common/Collectable'
import Category from '@client/store/Domain/Category/Category'
import PriceRangeDTO from '@microservice/Nuxtgen/API/DTO/PriceRangeDTO'
import { ProductFeatureValue } from '@client/store/Domain/Feature/ProductFeatureValue'

export default class ActiveFiltersModel {
	keyphrase: string
	brands: Collectable<Brand>
	category: Category
	features: Collectable<ProductFeatureValue>
	sort: number
	min_price: PriceRangeDTO

	toJSON(): string {
		return JSON.stringify({
			keyphrase: this.keyphrase,
			brands: this.brands,
			category: this.category,
			features: this.features,
			sort: this.sort,
			min_price: this.min_price
		})
	}
}
