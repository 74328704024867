import { Read } from '@client/store/Common/DataMapperContract'
import { ProductFeatureValueKP } from '@microservice/Product/API/Type/KeyProperties'
import { ProductFeatureValue } from '@client/store/Domain/Feature/ProductFeatureValue'
import { ProductFeatureValueFilterDTO } from '@microservice/Product/API/DTO/ProductFeatureValueFilterDTO'

export class ProductFeatureValueDM implements Read {
	responseDataToEntity(data: ProductFeatureValueFilterDTO): ProductFeatureValue {
		return new ProductFeatureValue(data)
	}

	responseDataToCollection(data: Record<ProductFeatureValueKP, ProductFeatureValueFilterDTO>): ProductFeatureValue[] {
		return Object.values(data).map((item) => new ProductFeatureValue(item))
	}
}
