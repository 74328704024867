import { Read } from '@client/store/Common/DataMapperContract'
import ProductFeature from '@client/store/Domain/Feature/ProductFeature'
import { ProductFeatureFilterDTO } from '@microservice/Product/API/DTO/ProductFeatureFilterDTO'

export class ProductFeatureDM implements Read {
	responseDataToEntity(data: Partial<ProductFeature>): ProductFeature {
		return new ProductFeature(data)
	}

	responseDataToCollection(data: Array<Partial<ProductFeature> | ProductFeatureFilterDTO>): ProductFeature[] {
		return data.map((item) => new ProductFeature(item))
	}
}
