import { render, staticRenderFns } from "./ProductListing.vue?vue&type=template&id=5a101fa7&scoped=true&"
import script from "./ProductListing.vue?vue&type=script&lang=ts&"
export * from "./ProductListing.vue?vue&type=script&lang=ts&"
import style0 from "./ProductListing.vue?vue&type=style&index=0&id=5a101fa7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a101fa7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCard: require('/home/node/client/components/Product/ProductCard.vue').default})
