





















import VueHorizontal from 'vue-horizontal'
import Category from '@client/store/Domain/Category/Category'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
	name: 'CategoryListingItemSmall',
	components: { FedraLink, VueHorizontal }
})
export default class CategoryListingItemSmall extends Vue {

	@Prop({ type: Object, required: true })
	category!: Category

	@Prop({ type: String, required: true })
	categoryName!: string

	@Prop({ type: String, required: true })
	categoryThumbnailImage!: string

}
