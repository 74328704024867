export default class Pagination {

	page: number
	itemsPerPage: number
	totalPageItems: number

	constructor(page: number, itemsPerPage: number, totalPageItems: number) {
		this.page = page
		this.itemsPerPage = itemsPerPage
		this.totalPageItems = totalPageItems
	}
}
