
















import { map, split, join } from 'lodash'
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import PageHeader from '@client/components/PageHeader/PageHeader.vue'
import PageFooter from '@client/components/PageFooter/PageFooter.vue'

@Component({
	name: 'nuxt-error',
	layout: 'error',
	components: { PageHeader, PageFooter, FedraLink }
})
export default class NuxtErrorLayout extends Vue {
	@Prop() error
	get errorCodeSplit(): { char: string, spanClass: string }[] {
		let statusCode = this.error.statusCode
		if (418 === statusCode) { statusCode += '🫖' }
		return map(split(statusCode, ''), (char, i) => {
			return { char, spanClass: i % 2 == 0 ? 'even' : 'odd' }
		})
	}
}
